import { Component, DoCheck } from '@angular/core';
import { GenericMeasureService } from '../tool-panel/custom/generic-measure/generic-measure.service';
import { GenericMeasure } from "../tool-panel/custom/generic-measure/domain/generic-measure"; 
import { ScenarioModelService } from '../scenario-model.service';
import { HttpClient } from "@angular/common/http";
import { environment }   from '../../environments/environment'; 

@Component({
  selector: 'app-generic-measure-popup',
  templateUrl: './generic-measure-popup.component.html',
  styleUrls: ['./generic-measure-popup.component.scss']
})
export class GenericMeasurePopupComponent implements DoCheck {

  constructor(
      private http: HttpClient, 
      public genericMeasureService: GenericMeasureService,
      public scenarioModel: ScenarioModelService
       )  {   

  }

  public actualMeasure: GenericMeasure = new GenericMeasure(); 
	public modality: number;
	public numAreaRows: number;
	public numSelectedRows: number;
  public error: string;
  public resultSample: any;
  public description: string;

  ngDoCheck() {
    if (this.genericMeasureService == null || this.genericMeasureService.actualMeasure == null) return;
    if (this.genericMeasureService.actualMeasure.modality != this.actualMeasure.modality ||
        this.genericMeasureService.actualMeasure.wktArea != this.actualMeasure.wktArea ||
        this.genericMeasureService.actualMeasure.whereClause != this.actualMeasure.whereClause ||
        this.genericMeasureService.actualMeasure.updateClause != this.actualMeasure.updateClause
      ) {
      this.actualMeasure = new GenericMeasure(this.genericMeasureService.actualMeasure.modality, this.genericMeasureService.actualMeasure.wktArea, this.genericMeasureService.actualMeasure.whereClause, this.genericMeasureService.actualMeasure.updateClause);
      this.loadData();
    }
    if (this.genericMeasureService.actualMeasure.description != this.description) {
      this.description = this.genericMeasureService.actualMeasure.description;
    }
  }
 
 
  loadData() {
    this.modality = 0; 
    const requestObject = {
      scenarioId: this.scenarioModel.scenario.id,
      modality: this.actualMeasure.modality,
      wktArea: this.actualMeasure.getWktAreaSanitized(),
      whereClause: this.actualMeasure.getWereClauseSanitized(),
      updateClause: this.actualMeasure.getUpdateClausedSanitized(),
      description: this.actualMeasure.getDescriptionSanitized()
    };
 
    const url = environment.serverUrl + "/rest/generic-measure/preview";
    this.http.post(url, requestObject).subscribe(
      response => { 
        this.modality = response["modality"];
        this.numAreaRows = response["numAreaRows"];
        this.numSelectedRows = response["numSelectedRows"];
        this.error = response["error"];
        this.resultSample = response["resultSample"];
      }
    );
 
  }

}
