import { Component } from '@angular/core';
import { LanguageModelService } from "../language-model.service";
import { ScenarioModelService } from '../scenario-model.service';
import { SourceModelService } from '../source-model.service';
import { WindowModelService } from '../window-model.service';
import { UserModelService } from '../user-model.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent { 

  constructor(public userModel: UserModelService, public windowModel: WindowModelService, public scenarioModel: ScenarioModelService, public sourceModel: SourceModelService, public languageModel: LanguageModelService) { }

}
