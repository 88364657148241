export class GenericMeasure {
    
    constructor(
        public modality: number = 1,
        public wktArea: string = "",
        public whereClause: string = "",
        public updateClause: string = "",
        public description: string = ""      
    ) { }

    public getShortDescription(): string { 
        if (this.description == null || this.description.trim().length == 0) {
            return "(geen beschrijving)";
        } else if (this.description.length > 20) {
            return this.description.substring(0,17) + " ...";
        } else {
            return this.description;
        }
    }

    public asText(): string {
        let te = "";
        te += this.sanitize(this.modality + "") + "|";
        te += this.sanitize(this.description) + "|";
        te += this.sanitize(this.wktArea) + "|";
        te += this.sanitize(this.whereClause) + "|";
        te += this.sanitize(this.updateClause);
        return te;
    }

    public getWktAreaSanitized(): string {
        return this.sanitize(this.wktArea);
    };

    public getWereClauseSanitized(): string {
        return this.sanitize(this.whereClause);
    };

    public getUpdateClausedSanitized(): string {
        return this.sanitize(this.updateClause);
    };

    public getDescriptionSanitized(): string {
        return this.sanitize(this.description);
    };

    private sanitize(te: string): string {
        te = te.replace("'", "");
        te = te.replace("`", "");
        te = te.replace("|", "");
        te = te.replace("&", "");
        te = te.replace('"', '');
        te = te.replace("\n", ""); 
        return te;
    }

}

