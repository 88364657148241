import { Component } from "@angular/core";
import { LanguageModelService } from "../language-model.service";
import { ToolModelService } from "../tool-model.service";
import { WindowModelService } from "../window-model.service";

@Component({
    selector: "app-tool-panel",
    templateUrl: "./tool-panel.component.html",
    styleUrls: ["./tool-panel.component.scss"]
})
export class ToolPanelComponent {
    
    constructor(public windowModel: WindowModelService, public l: LanguageModelService, public toolModel: ToolModelService) { }
    
    byName(item1: any, item2: any): boolean { 
        if (!item1 || !item2) return false;
        return item1.name == item2.name;
    }
}
