import { Component } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { CustomComponent } from "../custom.component";
import { LanguageModelService } from "../../../language-model.service";
import { ScenarioModelService } from "../../../scenario-model.service";

@Component({
    selector: "app-speed-capacity-measure-tool",
    templateUrl: "./speed-capacity-measure-tool.component.html",
    styleUrls: ["./speed-capacity-measure-tool.component.scss"]
})
export class SpeedCapacityMeasureToolComponent extends CustomComponent {

    constructor(public l: LanguageModelService, private scenarioModel: ScenarioModelService) {
        super();
    }

    addMeasure() {
        console.log(this.tool.params[0].option.value);
        console.log(this.tool.params[0].option.params[0].option.value);
        console.log(this.tool.params[0].option.params[1].option.value);
        console.log(this.tool.params[0].option.params[2].option.value);
        
        const url = "http://app.movemeter.com/php/mapserver/SetWegvakken.php&ids=~50118382~50416186&operatie=" + this.tool.params[0].option.value + "&waarde=" + this.tool.params[0].option.params[0].option.value + "&spits=" + this.tool.params[0].option.params[1].option.value + "&freeflow=" + this.tool.params[0].option.params[2].option.value + "&Scenarionr=11139&logboek=&SID=";
        console.log(url);
        
        this.scenarioModel.measureScenario.measures.push({name: "Snelheidsmaatregel", url: url});
    }

}
