declare var CenterScale: any;

import { Injectable, NgZone } from "@angular/core"; 
import { HttpClient } from '@angular/common/http';
import { Scenario }   from './scenario';
import { UserModelService } from './user-model.service'; 
import { NineyDefaultService } from "./niney-default.service";
import { environment }   from '../environments/environment';  

@Injectable({
    providedIn: "root"
})
export class ScenarioModelService {
    private user;
    
    loading: boolean = true;
    scenarios: Scenario[] = [];
    scenario: Scenario = null;
    measureScenario: Scenario = null;
    callbackOnScenarioChanged;

    constructor(
        private zone: NgZone,
        private http: HttpClient,
        private nineyDefault: NineyDefaultService,
        public userModel: UserModelService
    ) { 
        this.zone.onMicrotaskEmpty.subscribe({
            next: () => {
                if (this.user != this.userModel.user) {
                    this.user = this.userModel.user;
                    this.loadScenarios();
                }
            }
        });
    }
    
    public setScenario(scenario: Scenario) {
        this.scenario = scenario;
        this.measureScenario = null;
        
        if ((scenario != null) && (scenario.x != null) && (scenario.y != null)) {
            this.nineyDefault.defaultFocusModel.setCenterScale(new CenterScale(scenario.x, scenario.y, 108336.00408304202));
        }
        if (this.callbackOnScenarioChanged) this.callbackOnScenarioChanged.scenarioChanged();
    }

    public subscribeOnScenarioChanged(callbackOnScenarioChanged) {
        this.callbackOnScenarioChanged = callbackOnScenarioChanged;
    }
 
    private loadScenarios() {
        this.scenarios = [];
        
        let scenarioId = null;
        let measureScenarioId = null;
        if (this.scenario != null) {
            scenarioId = this.scenario.id;
            this.scenario = null;
        }
        if (this.measureScenario != null) {
            measureScenarioId = this.measureScenario.id;
            this.measureScenario = null;
        }
        
        if (this.user == null) {
            return;
        }
        
        this.loading = true;
        const url = environment.serverUrl + "/rest/area/getScenarios/" + this.user.id;
        this.http.get(url).subscribe((response: Object[]) => {
            this.loading = false;
            if (response && response.length > 0) {
              this.parseScenarios(response, scenarioId, measureScenarioId);
            }
        }); 
    }

    parseScenarios(response: any, previousScenarioId, previousMeasureScenarioId) {
        this.scenarios = [];  
        let tel:number = 1;
        for (let jsonScenario of <any[]>response) {
            if (jsonScenario.accessibilityIndicatorVariants.length &&
                jsonScenario.accessibilityIndicatorVariants.length > 0) {
                    if (tel==1) jsonScenario.referenceLine = '-0.10; -0.012; 1.203; 0.532; 8; 14; -0.085; -0.009; 1.071; 0.676; 8; 14;' + jsonScenario.name;
                    if (tel==2) jsonScenario.referenceLine = '-0.10; -0.012; 1.203; 0.532; 10; 16; -0.085; -0.009; 1.071; 0.676; 8; 14;' + jsonScenario.name;
                    if (tel==3) jsonScenario.referenceLine = '-0.10; -0.02; 1.203; 1.532; 8; 14; -0.085; -0.009; 1.071; 0.676; 8; 14;' + jsonScenario.name;
                    if (tel==4) jsonScenario.referenceLine = '-0.12; -0.009; 1.203; 0.532; 6; 14; -0.085; -0.009; 1.071; 0.676; 8; 14;' + jsonScenario.name;
                    tel++;
                } else {
                    jsonScenario.referenceLine = '';
            }
            jsonScenario.measures = [];
            jsonScenario.measureScenarios = [];
            if (jsonScenario.basedOnId == 0) {
                this.scenarios.push(jsonScenario);
                if (previousScenarioId == jsonScenario.id) {
                    this.setScenario(jsonScenario);
                }
            }
        }
        if (this.scenario == null) {
            this.setScenario(this.scenarios[0]);
        }
        for (let jsonScenario of <any[]>response) {
            if (jsonScenario.basedOnId > 0) {
                let baseScenario = null;
                for (let scenario of this.scenarios) {
                    if (scenario.id == jsonScenario.basedOnId) {
                        baseScenario = scenario;
                        break;
                    }
                }
                if (baseScenario == null) {
                    console.log("WARN: measure scenario " + jsonScenario.id + " is based on scenario " + jsonScenario.basedOnId + ", but it does not exist.");
                } else {
                    baseScenario.measureScenarios.push(jsonScenario);
                    if ((this.scenario == baseScenario) && (previousMeasureScenarioId == jsonScenario.id)) {
                        this.measureScenario = jsonScenario;
                    }
                }
            }
        }
    }
}
