import { Injectable } from '@angular/core';
import { GtfsModelService } from './gtfs-model.service';
import { HttpClient, HttpHeaders } from "@angular/common/http"; 
import { Trip }          from './domain/trip'; 
import { Stop }          from './domain/stop'; 
import { Stops }         from './domain/stops'; 
import { StopOnTrip }    from './domain/stop-on-trip';
import { Departure }     from './domain/departure';
import { TimeFunctions } from './domain/time-functions'; 
import { environment }   from '../../../../environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class GtfsEditModelService {

  constructor(private http: HttpClient) { }

  public ptStops: Stops = new Stops();
  public extraTrips: Trip[] = [];
  public deletedTrips: number[] = [];
  public isEditing: boolean = false;
  public ptStopClicked: {stop: Stop, x: number, y: number} = null;
  public needsRedraw: boolean = false;

  public SORT_OF_TRIPS = {
    onlyThis: 1,
    allOfThisLineThisDirection: 2
  }

  public getChangesForScenario(measureScenario) {
    let url:string = environment.serverUrl + "/rest/gtfs/getChangesForScenario/" + measureScenario.id; 

    this.http.get(url).subscribe(
      response => {  
        this.extraTrips = Trip.parseTrips((<any>response).extraTrips); 

        let stops: Stop[] = []
        for (let stop of (<any>response).extraStops) {
          stops.push(Stop.parseStop(stop));
        }
        this.ptStops.setExtraStops(stops);
         
        let deletedTrips: number[] = [];
        for (let deletedTrip of (<any>response).deletedTrips) {
          deletedTrips.push(parseInt(deletedTrip));
        }
        this.deletedTrips = deletedTrips; 

        this.needsRedraw = true;
      }
    );    
  }

  public putChangesForScenario(measureScenario) { 
    let url:string = environment.serverUrl + "/rest/gtfs/gtfs-scenariodata/" + measureScenario.id; 
    this.http.put(url, {
      extraTrips: Trip.tripsForDatabase(this.extraTrips),
      extraStops: this.ptStops.getExtraStopsForDatabase(), 
      deletedTrips: this.deletedTrips
    }).subscribe(
      response => {
        console.log(response);
      }
    );
              
  }

  public deleteTripGroup(gtfsModel:GtfsModelService, actionForWhichSortOfTrips) {
    if (actionForWhichSortOfTrips == this.SORT_OF_TRIPS.onlyThis) {
      this.deleteTrip(gtfsModel.selectedTripId);
    }
    if (actionForWhichSortOfTrips == this.SORT_OF_TRIPS.allOfThisLineThisDirection) {
      for (let departure of gtfsModel.selectedPtStopBack.departures) {
        if (departure.routeShortName == gtfsModel.selectedTripProperties.routeShortName) {
          this.deleteTrip(departure.tripId);
        }
      }  
    }
  }

  public deleteTrip(tripId) { 
    if (this.isDeleted(tripId)) return;  // already deleted, nothing to do
    if (tripId >= 0) {                   // base schedule trip, mark as deleted
      this.deletedTrips.push(tripId);   
    } else {                             // extra trip, remove from memory object
      let newExtraTrips = [];
      for (let extraTrip of this.extraTrips) {
        if (extraTrip.getNumberStops() > 0 && extraTrip.id == tripId) {
          newExtraTrips.push(Trip.emptyTrip());  // empty trip because index is tripnumber
        } else {
          newExtraTrips.push(extraTrip);
        }
      }
      this.extraTrips = newExtraTrips;
    }
  }

  public isDeleted(tripId: number): boolean {
    return (this.deletedTrips.indexOf(tripId) >= 0);
  }

  public repeatTripGroup(gtfsModel:GtfsModelService, actionForWhichSortOfTrips, repetitionValues) {
      if (actionForWhichSortOfTrips == this.SORT_OF_TRIPS.onlyThis) {
        this.repeatTrip(gtfsModel.selectedTrip, gtfsModel.selectedTripProperties, repetitionValues);
      }
      if (actionForWhichSortOfTrips == this.SORT_OF_TRIPS.allOfThisLineThisDirection) {
        for (let departure of gtfsModel.selectedPtStopBack.departures) {
          if (departure.routeShortName == gtfsModel.selectedTripProperties.routeShortName) {
            let offsetBetweenOtherTripAndSelectedTrip = TimeFunctions.getTimeDifferenceMinutes(departure.departureTime, gtfsModel.selectedTripProperties.departureTime);
            let correctedRepetitionValues = [];
            for (let repetitionValue of repetitionValues) {
              correctedRepetitionValues.push(parseInt(repetitionValue) + offsetBetweenOtherTripAndSelectedTrip);
            }
            this.repeatTrip(gtfsModel.selectedTrip, gtfsModel.selectedTripProperties, correctedRepetitionValues);
          }
        }  
      }
  }

  public executeAddLine(stopId: number, addLijnDeparture: string, addLijnNummer: string, addLijnHeadSign: string) { 
    let newTripId: number = this.getNewTripId(0); 
    let newTrip: Trip = new Trip(
      newTripId,
      addLijnNummer,
      addLijnHeadSign,
      [ new StopOnTrip(
            stopId, 
            TimeFunctions.timeOffsetMinutes(addLijnDeparture, 0), 
            TimeFunctions.timeOffsetMinutes(addLijnDeparture, 0))
      ]
    );
    this.extraTrips.push(newTrip);
  }

  public repeatTrip(selectedTrip, selectedTripProperties, repetitionValues) { 
    for (let offsetMinuten of repetitionValues) { 
      let newTripId: number = this.getNewTripId(0); 
      let newTrip: Trip = new Trip(
        newTripId,
        selectedTripProperties.routeShortName,
        selectedTripProperties.tripHeadsign,
        []
      );
       
      for (let stopOnTrip of selectedTrip.stopsOnTrip) { 
        newTrip.addStopOnTrip(new StopOnTrip(
              stopOnTrip.stopId, 
              TimeFunctions.timeOffsetMinutes(stopOnTrip.departureTime, offsetMinuten), 
              TimeFunctions.timeOffsetMinutes(stopOnTrip.arrivalTime, offsetMinuten)));
      } 
      this.extraTrips.push(newTrip);
    }; 
  }

  public changeTravelTimeTripGroup(
    gtfsModel:GtfsModelService, 
    actionForWhichSortOfTrips: number,
    changeStopTime,   timeActionIdChangeStopTime,   valueChangeStopTime, 
    changeTravelTime, timeActionIdChangeTravelTime, valueChangeTravelTime, 
    selectedTrip: Trip
    ) { 
    if (actionForWhichSortOfTrips == this.SORT_OF_TRIPS.onlyThis) {
      this.changeTravelTimeTrip(0, gtfsModel.selectedTripId, selectedTrip, gtfsModel.selectedTripProperties,
        changeStopTime,   timeActionIdChangeStopTime,   valueChangeStopTime, 
        changeTravelTime, timeActionIdChangeTravelTime, valueChangeTravelTime);
    }
    if (actionForWhichSortOfTrips == this.SORT_OF_TRIPS.allOfThisLineThisDirection) {
      for (let departure of gtfsModel.selectedPtStopBack.departures) {
        if (departure.routeShortName == gtfsModel.selectedTripProperties.routeShortName) { 
          let offsetBetweenOtherTripAndSelectedTrip = TimeFunctions.getTimeDifferenceMinutes(departure.departureTime, gtfsModel.selectedTripProperties.departureTime);
          this.changeTravelTimeTrip(offsetBetweenOtherTripAndSelectedTrip, departure.tripId, selectedTrip, gtfsModel.selectedTripProperties,
            changeStopTime,   timeActionIdChangeStopTime,   valueChangeStopTime, 
            changeTravelTime, timeActionIdChangeTravelTime, valueChangeTravelTime);
        }
      }   
    }
  }
  

  private replaceTrip(newTrip: Trip, tripId: number) {
    if (tripId < 0) {        // already extra trip, overwrite in place
      for (let i = 0; i < this.extraTrips.length; i++) { 
          if (this.extraTrips[i].id == newTrip.id) {
            this.extraTrips[i] = newTrip;
            break;
          } 
      }
    } else {                 // base schedule trip; add to extra trips and mark as deleted
      this.extraTrips.push(newTrip);
      this.deleteTrip(tripId);
    } 
  }

  public changeTravelTimeTrip(initialOffset, tripId, selectedTrip, selectedTripProperties,
                              changeStopTime,   timeActionIdChangeStopTime,   valueChangeStopTime, 
                              changeTravelTime, timeActionIdChangeTravelTime, valueChangeTravelTime ) {
    let newTripId: number = this.getNewTripId(tripId);
    let newTrip: Trip = new Trip(
      newTripId,
      selectedTripProperties.routeShortName,
      selectedTripProperties.tripHeadsign,
      []
    );
    let offset: number = initialOffset;
    for (let i = 0; i < selectedTrip.length; i++) {
      let thisStop = selectedTrip[i];
      let nextStop = thisStop; 
      if (i < selectedTrip.length - 1) {
        nextStop = selectedTrip[i + 1];
      } 
      let arrivalTime = TimeFunctions.timeOffsetSeconds(thisStop.arrivalTime, offset);

      // now change offset for stop time
      if (changeStopTime && thisStop.selectedForEdit) {
        let extraStopSeconds: number = 0;
        if (timeActionIdChangeStopTime == 1 || timeActionIdChangeStopTime == 2) {
          extraStopSeconds = Number(valueChangeStopTime);
          if (timeActionIdChangeStopTime == 1) extraStopSeconds = -extraStopSeconds;
        }
        if (timeActionIdChangeStopTime == 3 || timeActionIdChangeStopTime == 4) {
          let actualStopTime = TimeFunctions.getTimeDifferenceSeconds(thisStop.departureTime, thisStop.arrivalTime);
          extraStopSeconds = Number(valueChangeStopTime) / 100 * actualStopTime;
          if (timeActionIdChangeStopTime == 3) extraStopSeconds = -extraStopSeconds;
        }      
        offset += extraStopSeconds;        
      }
      let departureTime = TimeFunctions.timeOffsetSeconds(thisStop.departureTime, offset);

      // now change offset for travel time
      if (changeTravelTime && thisStop.selectedForEdit) {
        let extraTravelSeconds: number = 0;
        if (timeActionIdChangeTravelTime == 1 || timeActionIdChangeTravelTime == 2) {
          extraTravelSeconds = Number(valueChangeTravelTime);
          if (timeActionIdChangeStopTime == 1) extraTravelSeconds = -extraTravelSeconds;
        }
        if (timeActionIdChangeTravelTime == 3 || timeActionIdChangeTravelTime == 4) {
          let actualTravelTime = TimeFunctions.getTimeDifferenceSeconds(nextStop.arrivalTime, thisStop.departureTime);
          extraTravelSeconds = Number(valueChangeTravelTime) / 100 * actualTravelTime;
          if (timeActionIdChangeStopTime == 3) extraTravelSeconds = -extraTravelSeconds;
        }      
        offset += extraTravelSeconds; 
      }
      newTrip.addStopOnTrip(new StopOnTrip(
        thisStop.stopId, 
        departureTime, 
        arrivalTime)); 
    }
    this.replaceTrip(newTrip, tripId);
  } 

  public removeStopFromTripGroup(
    gtfsModel: GtfsModelService,
    actionForWhichSortOfTrips,
    savedTravelTimeForEachRemovedStop,
    selectedTrip) {
      if (actionForWhichSortOfTrips == this.SORT_OF_TRIPS.onlyThis) {
        this.removeStopFromTrip(0, gtfsModel.selectedTripId, selectedTrip, gtfsModel.selectedTripProperties,
          savedTravelTimeForEachRemovedStop);
      }
      if (actionForWhichSortOfTrips == this.SORT_OF_TRIPS.allOfThisLineThisDirection) {
        for (let departure of gtfsModel.selectedPtStopBack.departures) {
          if (departure.routeShortName == gtfsModel.selectedTripProperties.routeShortName) { 
            let offsetBetweenOtherTripAndSelectedTrip = TimeFunctions.getTimeDifferenceMinutes(departure.departureTime, gtfsModel.selectedTripProperties.departureTime);
            this.removeStopFromTrip(offsetBetweenOtherTripAndSelectedTrip, departure.tripId, selectedTrip, gtfsModel.selectedTripProperties,
              savedTravelTimeForEachRemovedStop);
          }
        }   
      }
  }

  
  public removeStopFromTrip(initialOffset, tripId, selectedTrip, selectedTripProperties,
                            savedTravelTimeForEachRemovedStop) {
    let newTripId: number = this.getNewTripId(tripId);
    let newTrip: Trip = new Trip(
      newTripId,
      selectedTripProperties.routeShortName,
      selectedTripProperties.tripHeadsign,
      []
    );
    let offset: number = initialOffset;
    for (let i = 0; i < selectedTrip.length; i++) {
      let thisStop = selectedTrip[i];
      let nextStop = thisStop; 
      if (i < selectedTrip.length - 1) {
        nextStop = selectedTrip[i + 1];
      } 

      if (thisStop.selectedForEdit) {
        offset = offset - savedTravelTimeForEachRemovedStop;
      } else {
        newTrip.addStopOnTrip(new StopOnTrip(
          thisStop.stopId, 
          TimeFunctions.timeOffsetSeconds(thisStop.departureTime, offset), 
          TimeFunctions.timeOffsetSeconds(thisStop.arrivalTime, offset)));  
      }      
    }
    this.replaceTrip(newTrip, tripId);
  }
 
  public addStopToTrip(gtfsModel, tripId, addStopMode, selectedStopRadioIndex, selectedTripProperties,
                       valueChangeTravelTime, valueChangeStopTime, valueNameNewStop, selectedTrip: Trip) {
    let newStopId = 0;
    if (addStopMode == 1) {
      newStopId = this.ptStopClicked.stop.id;
      valueNameNewStop = this.ptStopClicked.stop.name;
    } else {
      newStopId = this.addStop(valueNameNewStop, this.ptStopClicked.x, this.ptStopClicked.y);
    }  
    let newTravelTime = 0;
    if (selectedStopRadioIndex == -1 || selectedStopRadioIndex == gtfsModel.selectedTrip.length - 1) {  // last stop chosen, so add new stop at the end of the line
      newTravelTime = parseInt(valueChangeTravelTime);
    } else {                                                        // insert new stop in between two existing stops
      newTravelTime = TimeFunctions.getTimeDifferenceSeconds(
        gtfsModel.selectedTrip[selectedStopRadioIndex].arrivalTime,
        gtfsModel.selectedTrip[selectedStopRadioIndex - 1].departureTime) 
        / 2;
    }
    let newStopTime = parseInt(valueChangeStopTime);

    let newTripId: number = this.getNewTripId(tripId);
    let newTrip: Trip = new Trip(
      newTripId,
      selectedTrip.routeShortName,
      selectedTrip.tripHeadsign,
      []
    );
 
    let offset = 0;
    for (let i = 0; i < selectedTrip.stopsOnTrip.length; i++) {
      let thisStop = selectedTrip.stopsOnTrip[i];   
      newTrip.addStopOnTrip(new StopOnTrip(
        thisStop.stopId, 
        TimeFunctions.timeOffsetSeconds(thisStop.departureTime, offset), 
        TimeFunctions.timeOffsetSeconds(thisStop.arrivalTime, offset))); 

      if (i == selectedStopRadioIndex || (i == selectedTrip.stopsOnTrip.length - 1 && selectedStopRadioIndex == -1)) {
        newTrip.addStopOnTrip(new StopOnTrip(
          newStopId, 
          TimeFunctions.timeOffsetSeconds(thisStop.departureTime, newTravelTime + newStopTime), 
          TimeFunctions.timeOffsetSeconds(thisStop.arrivalTime, newTravelTime)));  
        offset += newStopTime;
      }      
    }
    this.replaceTrip(newTrip, tripId);
     
    gtfsModel.selectedTripId = newTripId;
    gtfsModel.selectedTrip = newTrip;
    gtfsModel.drawStops(gtfsModel.selectedTrip);
  }
   
  public adaptDepartures(selectedPtStop: Stop): Departure[] {
    let adaptedDepartures: Departure[] = [];    
    for (let trip of this.extraTrips) { 
      for (let stopOnTrip of trip.stopsOnTrip) {
        if (stopOnTrip.stopId == selectedPtStop.id) {  
          adaptedDepartures.push(new Departure(
            trip.id,
            stopOnTrip.departureTime,
            trip.tripHeadsign,
            "extra",
            trip.routeShortName,
            "",
            true  
          ));
        }
      }
    } 
    if (selectedPtStop.departures) {
      for (let departure of selectedPtStop.departures) {
        adaptedDepartures.push(departure);
      }
    }
     
    adaptedDepartures.sort(function(a, b) {
      if (a.tripHeadsign > b.tripHeadsign) return 1;
      if (a.tripHeadsign < b.tripHeadsign) return -1;
      // headsign equal:
      if (a.departureTime > b.departureTime) return 1;
      if (a.departureTime < b.departureTime) return -1;
      // both equal:
      return 0;
    });
    return adaptedDepartures; 
  }

  
  private getNewTripId(tripId: number): number {
    if (tripId < 0) {  // this is already an extra trip, overwrite
      return tripId;
    } else {           // this is a base trip, allocate new id
      for (let i = -1; true; i--) {
        if (this.getExtraTripForId(i) == null) {
          return i;
        }
      }
    }
  }

  public getExtraTripForId(tripId: number) {
    // console.log(this.extraTrips);
    for (let trip of this.extraTrips) {
      if (trip.id == tripId) return trip;
    }
    return null;
    // return this.extraTrips[-1 - tripId];
  }

  public ptStopClick(ptStop, point) {
    this.ptStopClicked = { stop: ptStop, x: point.x, y: point.y}
  }

  public addStop(name: string, x: number, y: number): number { 
    let stopId = this.ptStops.getFreeId();
    this.ptStops.pushStop(new Stop(stopId, name, x, y));
    return stopId;
  }

}
