declare var LineString: any;
declare var Point: any;
declare var Polygon: any;

import { Injectable } from "@angular/core";
import { SelectionModelService } from "./selection-model.service";

@Injectable({
    providedIn: "root"
})
export class SelectionPolygonControllerService {
    geometry = null;
    selectableFeatureTypes: any[] = [];
    
    constructor(private selectionModel: SelectionModelService) { }
    
    tapHandler(x, y, tapDuration, ctrlKey, shiftKey) {
        if (this.geometry == null) {
            this.setSelectionPoint(x, y, ctrlKey || shiftKey);
        } else {
            this.addXY(x, y);
        }
    }
    
    mouseMoveHandler(x, y) {
        if (this.geometry != null) {
            this.setXY(x, y);
        }
    }
    
    doubleClickHandler() {
        if (this.geometry != null) {
            this.setSelectionPolygon();
        }
    }
    
    setSelectionPoint(x, y, cumulative) {
/*        if ((x == null) || (y == null)) {
            this.selectionModel.reset();
            return;
        }*/
        
        const point = new Point(x, y);
        this.selectionModel.setSelectionGeometry(point, cumulative);
        for (let f of this.selectableFeatureTypes) {
            f.setSelectionGeometry(point, cumulative);
        }
    }
    
    setSelectionPolygon() {
        const startPoint = this.geometry.childGeometries[0];
        const endPoint = this.geometry.childGeometries[this.geometry.childGeometries.length - 1];
        endPoint.setXY(startPoint.x, startPoint.y);
        for (let f of this.selectableFeatureTypes) {
            f.setSelectionGeometry(new Polygon([this.geometry]), false);
        }
        this.resetGeometry();
    }
    
    resetSelection() {
        for (let f of this.selectableFeatureTypes) {
            f.setSelectionGeometry(null, false);
        }
    }
    
    toggleGeometry() {
        if (this.geometry == null) {
            this.setGeometry();
        } else {
            this.resetGeometry();
        }
    }
        
    setGeometry() {
        this.geometry = new Point(0, 0);
    }

    resetGeometry() {
        this.geometry = null;
    }
    
    setXY(x, y) {
        this.geometry.getEndPoint().setXY(x, y);
    }
    
    addXY(x, y) { 
        if (this.geometry instanceof Point) {
            this.geometry = new LineString([new Point(x, y), new Point(x, y)]);
        } else {
            this.geometry.addChild(new Point(x, y));
        }
    }
}
