import { Injectable } from '@angular/core';
import { GenericMeasure } from "./domain/generic-measure";

@Injectable({
  providedIn: 'root'
})
export class GenericMeasureService {

  constructor() { }

  public actualMeasure: GenericMeasure = null;
  public measures: GenericMeasure[] = [];

  addMeasure() {
    this.actualMeasure = new GenericMeasure();
    let i = 0;
    let tryDescription;
    while (true) { 
      tryDescription = "nieuwe maatregel";
      if (i > 0) tryDescription = tryDescription + " (" + i + ")";
      let found = false;
      for (let measure of this.measures) {
        if (measure.description == tryDescription) found = true;
      }
      if (!found) break;
      i++;
    }
    this.actualMeasure.description = tryDescription;
    this.measures.push(this.actualMeasure);
  }
  
    
  removeMeasure(index) { 
    let newMeasures = [];
    if (this.measures[index] == this.actualMeasure) {
      this.actualMeasure = null;
    }
    for (let i = 0; i < this.measures.length; i++) {
      if (i != index) {
        newMeasures.push(this.measures[i]);  
      }
    }
    this.measures = newMeasures;
    if (this.measures.length > 0 && this.actualMeasure == null) { 
      this.actualMeasure = this.measures[Math.max(0, index - 1)];
    }
  }

  moveMeasure(index, delta) {
    if (index + delta < 0) return;
    if (index + delta >= this.measures.length) return;
    let newMeasures = [];
    for (let i = 0; i < this.measures.length; i++) {
      if (i == index + delta && delta < 0) {
        newMeasures.push(this.measures[index]);  
      }
      if (i != index) { 
        newMeasures.push(this.measures[i]);
      }
      if (i == index + delta && delta > 0) {
        newMeasures.push(this.measures[index]);  
      }      
    }
    this.measures = newMeasures;
  }

  editMeasure(index) {
    this.actualMeasure = this.measures[index];
  }

  public adaptInClause(id) {
    let p = this.actualMeasure.whereClause.indexOf("[id] IN (");
    if (p == -1) {
      this.actualMeasure.whereClause += "[id] IN (" + id + ")";
    }
    let t = this.actualMeasure.whereClause.indexOf(")", p);
    let idArray = this.actualMeasure.whereClause.substring(p + 9, t - 1).split(",");
    if (!idArray.includes(id)) {
      idArray.push(id);
    }
    this.actualMeasure.whereClause = this.actualMeasure.whereClause.substring(0, p + 9) + idArray.join() + this.actualMeasure.whereClause.substring(t - 1);

  }


}
