declare var FeatureModel: any;
declare var FeatureType: any; 
declare var WKTConverter: any;
 
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class EmptyModelService extends (FeatureModel as { new(a: any, b: any): any; }) {
    
    constructor( ) {
        super([], new FeatureType("", new Array()));
    }

    public drawedGeometry: string;

    setSelectionGeometry(geometry, cumulative) {
        let wktConverter = new WKTConverter();
        let wkt:string = wktConverter.geometryToWKT(geometry);  
        this.drawedGeometry = wkt;
    }
}
