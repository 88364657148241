import { AfterViewInit, Component, DoCheck, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { WindowModelService } from '../window-model.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements AfterViewInit, DoCheck, OnDestroy {
  @ViewChild("header") private headerRef: ElementRef;
  @ViewChild("closeButton") private closeButtonRef: ElementRef;

  private pressX: Number;
  private pressY: Number;
  private pressHandler: EventListener = (function(mouseEvent) {
    mouseEvent.preventDefault();
    this.pressX = mouseEvent.offsetX;
    this.pressY = mouseEvent.offsetY;
    document.addEventListener("mousemove", this.mouseMoveHandler);
    document.addEventListener("mouseup", this.releaseHandler);
    this.focus();
  }).bind(this);
  private mouseMoveHandler: EventListener = (function(mouseEvent) {
    mouseEvent.preventDefault();
    this.setModelBounds(mouseEvent.clientX - this.pressX, mouseEvent.clientY - this.pressY);
  }).bind(this);
  private releaseHandler: EventListener = (function(mouseEvent) {
    document.removeEventListener("mousemove", this.mouseMoveHandler);
    document.removeEventListener("mouseup", this.releaseHandler);
  }).bind(this);
  private keyDownHandler: EventListener = (function(keyEvent) {
    if (keyEvent.keyCode == 27) {  // escape
      this.popup.visible = false;
    }
  }).bind(this);
  private resizeHandler: EventListener = (function(resizeEvent) {
    this.setModelBounds(this.popup.x, this.popup.y);
  }).bind(this);

  @Input() popup;
  @Output() close: EventEmitter<any> = new EventEmitter(true);

  constructor(private hostRef: ElementRef, public windowModel: WindowModelService) { }

  ngAfterViewInit() {
    if (this.popup.x == -1) {
      this.setModelBounds((document.documentElement.clientWidth - this.popup.width) / 2, this.popup.y);
    }
    this.headerRef.nativeElement.addEventListener("mousedown", this.pressHandler);
    this.hostRef.nativeElement.addEventListener("keydown", this.keyDownHandler);
    window.addEventListener("resize", this.resizeHandler);

    this.setElementBounds();
    this.focus();
  }

  ngDoCheck() {
    if (
      (this.hostRef.nativeElement.style.left != this.popup.x + "px") ||
      (this.hostRef.nativeElement.style.top != this.popup.y + "px") ||
      (this.hostRef.nativeElement.style.width != this.popup.width + "px")
     ) {
      this.setElementBounds();
    }
  }

  ngOnDestroy() {
    this.headerRef.nativeElement.removeEventListener("mousedown", this.pressHandler);
    this.hostRef.nativeElement.removeEventListener("keydown", this.keyDownHandler);
    window.removeEventListener("resize", this.resizeHandler);
    
    this.close.emit(null);
  }

  private setModelBounds(x, y) {
    if (x < 0) {
      this.popup.x = 0;
    } else if (x + this.popup.width > document.documentElement.clientWidth) {
      this.popup.x = document.documentElement.clientWidth - this.popup.width;
    } else {
      this.popup.x = x;
    }
    var windowHeight = parseInt(getComputedStyle(this.hostRef.nativeElement).getPropertyValue("height"));
    if (y < 0) {
      this.popup.y = 0;
    } else if (y + windowHeight > document.documentElement.clientHeight) {
      this.popup.y = document.documentElement.clientHeight - windowHeight;
    } else {
      this.popup.y = y;
    }
  }

  private setElementBounds() {
    this.hostRef.nativeElement.style.left = this.popup.x + "px";
    this.hostRef.nativeElement.style.top = this.popup.y + "px";
    this.hostRef.nativeElement.style.width = this.popup.width + "px";
  }
                
  focus() {
    this.hostRef.nativeElement.style.zIndex = this.windowModel.z++;
    this.closeButtonRef.nativeElement.focus();
  }
}
