
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class WindowModelService {
    constructor() { }

    menu = null;
    
    popups = [
        { title: "LINK INFORMATION", visible: false, x: -1, y: 150, width: 800 },        // segment info
        { title: "RITTEN", visible: false, x: -1, y: 150, width: 770 },                  // benchmark info
        { title: "REFERENTIELIJNEN", visible: false, x: -1, y: 150, width: 750 },        // benchmark info
        { title: "NEW BASE SCENARIO", visible: false, x: -1, y: 150, width: 750 },       // form to create a new base scenario
        { title: "IMPORT", visible: false, x: -1, y: 150, width: 750 },                  // form to import (=upload) data
        { title: "BRONMODEL-CONSISTENTIE", visible: false, x: -1, y: 150, width: 750 },  // source model consistency
        { title: "NIEUW MAATREGELSCENARIO", visible: false, x: -1, y: 150, width: 600 }, // new measure scenario
        { title: "GENERIEKE MAATREGEL", visible: false, x: -1, y: 150, width: 800 }      // generic measure
    ];
    z = 100;
    
    resetMenu() {
        this.menu = null;
    }
    
    toggleMenu(name) {
        if (this.menu == name) {
            this.menu = null;
        } else {
            this.menu = name;
        }
    }
    
    setPopupVisible(i, visible) {
        if (this.popups[i] == null) {
            return;
        }
        this.popups[i].visible = visible;
    }
    
    setPopupsVisible(a, visible) {
        if (a == null) {
            return;
        }
        for (let i = 0; i < a.length; i++) {
            this.popups[a[i]].visible = visible;
        }
    }
    
    togglePopupVisible(i) {
        if (this.popups[i] == null) {
            return;
        }
        this.popups[i].visible = !this.popups[i].visible;
    }
    
    arePopupsVisible(a, visible) {
        if (a == null) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            if (this.popups[a[i]].visible != visible) {
                return false;
            }
        }
        return true;
    }
    
    setPopupWidth(i, width) {
        if (this.popups[i].x + width > document.documentElement.clientWidth) {
            this.popups[i].x = document.documentElement.clientWidth - width;
        }
        this.popups[i].width = width;
    }
}
