import { Input } from "@angular/core";

export class MapLayer {
    @Input() layer;
    boundsModel;
    focusModel;
    envelopeModel;
    
    init(boundsModel, focusModel, envelopeModel) {
        this.boundsModel = boundsModel;
        this.focusModel = focusModel;
        this.envelopeModel = envelopeModel;
    }
}
