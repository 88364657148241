declare var Bounds: any;

export class ChartModel {
    bounds = new Bounds(400, 300);
    offset = [10, 48, 51, 68];
    xGrid = {
        label: "",
        unit: "",
        x1: -1,
        y1: -1,
        x2: -1,
        y2: -1,
        values: [-100, -50, 0, 50, 100, 150]
    };
    yGrid = {
        label: "",
        unit: "",
        x1: -1,
        y1: -1,
        x2: -1,
        y2: -1,
        values: [0, 20000, 40000, 60000, 80000, 100000]
    };
    logarithmic = false;
    
    constructor() {
        this.setGridCoords();
    }
    
    setBounds(bounds) {
        this.bounds = bounds;
        this.setGridCoords();
    }
    
    setOffset(offset) {
        this.offset = offset;
        this.setGridCoords();
    }
    
    setGridCoords() {
        this.xGrid.x1 = this.offset[3];
        this.xGrid.y1 = this.bounds.height - this.offset[2];
        this.xGrid.x2 = this.bounds.width - this.offset[1];
        this.xGrid.y2 = this.bounds.height - this.offset[2];
        this.yGrid.x1 = this.offset[3];
        this.yGrid.y1 = this.offset[0];
        this.yGrid.x2 = this.offset[3];
        this.yGrid.y2 = this.bounds.height - this.offset[2];
    }
    
    setGridValues(minX, maxX, minY, maxY) {
        if (minX < 0) {
            this.xGrid.values = [minX, 0, Math.round(0.25 * maxX), Math.round(0.5 * maxX), Math.round(0.75 * maxX), maxX];
        } else {
            this.xGrid.values = [0, Math.round(0.25 * maxX), Math.round(0.5 * maxX), Math.round(0.75 * maxX), maxX];
        }
        if (minY < 0) {
            this.yGrid.values = [minY, 0, Math.round(0.25 * maxY), Math.round(0.5 * maxY), Math.round(0.75 * maxY), maxY];
        } else {
            this.yGrid.values = [minY, Math.round(0.25 * maxY), Math.round(0.5 * maxY), Math.round(0.75 * maxY), maxY];
        }
    }
    
    transGridX(value) {
        let minValue = this.xGrid.values[0];
        if (minValue > 0) minValue = 0;
        let maxValue = this.xGrid.values[this.xGrid.values.length - 1];
        let maxWidth = this.xGrid.x2 - this.offset[3]; 
        return this.xGrid.x1 + (value - minValue) * maxWidth / (maxValue - minValue);
    }
    
    transGridY(value) {
        let minValue = this.yGrid.values[0];
        if (minValue > 0) minValue = 0;
        let maxValue = this.yGrid.values[this.yGrid.values.length - 1];
        let maxHeight = this.yGrid.y2 - this.offset[0];
        if (this.logarithmic) {
            //return this.yGrid.y2 - Math.log10((value - minValue) * 10 / (maxValue - minValue) + 1) * maxHeight;
            return this.yGrid.y2 - Math.sqrt((value - minValue) * 25 / (maxValue - minValue)) * maxHeight / 5;
        }
        return this.yGrid.y2 - (value - minValue) * maxHeight / (maxValue - minValue);
    }
}
