import { Stop }   from './stop';
import { StopOnTrip }   from './stop-on-trip';

export class Stops {
    
    private baseStops: Stop[];
    private extraStops: Stop[];

    constructor( ) {
        this.baseStops = [];
        this.extraStops = [];
     }
    
    public getPtStops(includingExtraStops = true) {
        if (includingExtraStops) {
          return this.baseStops.concat(this.extraStops);
        } else {
          return this.baseStops;
        } 
    }

    setBaseStops(stops: Stop[]): void {
        this.baseStops = stops;
    }

    setExtraStops(stops: Stop[]): void {
        this.extraStops = stops;
    }

    getExtraStopsForDatabase(): Stop[] {
        return this.extraStops; 
    }
 
    public getFreeId(): number {
        for (let i = -1 - this.extraStops.length; true; i--) {  // begin with length for performance
            if (this.getById(i) == null) {
              return i;
            }
        } 
    };
  
    public pushStop(stop: Stop): void {
        this.extraStops.push(stop);
    }

    public getClosest(point, includingExtraStops = true): Stop { 
        let x = point.x;
        let y = point.y;
        let minDistance2 = 999999999999;
        let distance2 = 0;
        let dx = 0;
        let dy = 0;
        let minPtStop = null;
        for (let stop of this.getPtStops(includingExtraStops)) {
            dx = x - stop.x;
            dy = y - stop.y;
            distance2 = (dx*dx + dy*dy);
            if (distance2 < minDistance2) {
                minDistance2 = distance2;
                minPtStop = stop;
            }
        }
        return minPtStop;
    }

    public getStopNameForStopOnTrip(stopOnTrip: StopOnTrip): string {
        let stop: Stop = this.getById(stopOnTrip.stopId);
        if (!stop) return ("onbekend: " + stopOnTrip.stopId);
        return stop.name;
    }

    public getById(stopId: number): Stop { 
        for (let stop of this.getPtStops()) { 
          if (stop.id == stopId) {
            return stop;
          }
        }   
        return null;
      }
}

