declare var MapFeatureModel: any;

import { DoCheck, Input, OnChanges, SimpleChanges } from "@angular/core";

export class Symbolizer implements DoCheck, OnChanges {
    mapFeatureModel = new MapFeatureModel();
    
    @Input("maxscale") maxScale;
    @Input("filter") filterExpression;
    @Input("envelopecheck") envelopeCheck;
    @Input("propertyindex") propertyIndex;
    @Input("deepwatch") deepWatch;
    @Input("geometries") geometries;
    @Input("geometry") geometry;
    boundsModel;
    focusModel;
    envelopeModel;
    featureModel;
    
    init(boundsModel, focusModel, envelopeModel, featureModel) {
        this.boundsModel = boundsModel;
        this.focusModel = focusModel;
        this.envelopeModel = envelopeModel;
        this.featureModel = featureModel;
    }
    
    ngDoCheck() {
        if ((this.boundsModel != null) && (this.mapFeatureModel.bounds != this.boundsModel.bounds)) {
            if (this.envelopeCheck || (<any> this).inverseFill) {
                this.mapFeatureModel.envelope = this.envelopeModel.getAnimationEnvelope();
            }
            this.mapFeatureModel.setBounds(this.boundsModel.bounds);
        }
        if ((this.focusModel != null) && (this.mapFeatureModel.centerScale != this.focusModel.animationCenterScale)) {
            if (this.envelopeCheck || (<any> this).inverseFill) {
                this.mapFeatureModel.envelope = this.envelopeModel.getAnimationEnvelope();
            }
            this.mapFeatureModel.setCenterScale(this.focusModel.animationCenterScale);
        }
        if ((this.featureModel != null) && (this.mapFeatureModel.features != this.featureModel.features)) {
            this.mapFeatureModel.setFeatures(this.featureModel.features);
        }
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if (changes.maxScale) {
            this.mapFeatureModel.maxScale = this.maxScale;
        }
        if (changes.filterExpression) {
            this.mapFeatureModel.filterExpression = this.filterExpression;
        }
        if (changes.envelopeCheck) {
            this.mapFeatureModel.envelopeCheck = this.envelopeCheck;
        }
        if (changes.propertyIndex) {
            this.mapFeatureModel.propertyIndex = this.propertyIndex;
        }
        if (changes.deepWatch) {
            this.mapFeatureModel.deepWatch = this.deepWatch;
        }
        if (changes.geometries) {
            this.mapFeatureModel.setGeometries(this.geometries);
        }
        if (changes.geometry) {
            this.mapFeatureModel.setGeometry(this.geometry);
        }
    }
}
