declare var FeatureModel: any;
declare var FeatureType: any;
declare var Property: any;
declare var PropertyType: any;

import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class PtLineModelService extends (FeatureModel as { new(a: any, b: any): any; }) {
    
    constructor() {
        super([], new FeatureType("ptLines", new Array(
            new Property("id", PropertyType.prototype.INTEGER),
            new Property("type", PropertyType.prototype.INTEGER),
            new Property("selected", PropertyType.prototype.INTEGER),
            new Property("geometry", PropertyType.prototype.GEOMETRY)
        )));
    }
}
