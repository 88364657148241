declare var Bounds: any;
declare var CenterScale: any;
declare var MapController: any;

import { AfterContentInit, Component, ContentChildren, DoCheck, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { NineyDefaultService } from "../niney-default.service";
import { MapLayer } from "./map-layer";

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterContentInit, DoCheck, OnChanges {
    @ContentChildren(MapLayer) private contentChildren;
    private mapControllerEnv;
    private mapController;
    
    @Input("boundsmodel") boundsModel;
    @Input("focusmodel") focusModel;
    @Input("envelopemodel") envelopeModel;
    @Input("mousewheelaction") mouseWheelAction;
    @Input("nTap") tapFunction;
    @Input("nPress") pressFunction;
    @Input("nRelease") releaseFunction;
    @Input("nMousemove") mouseMoveFunction;
    
    constructor(private hostRef: ElementRef, private nineyDefaultService: NineyDefaultService) {
        this.boundsModel = nineyDefaultService.defaultBoundsModel;
        this.focusModel = nineyDefaultService.defaultFocusModel;
        this.envelopeModel = nineyDefaultService.defaultEnvelopeModel;
        this.mouseWheelAction = "ZOOM";
    }
    
    ngAfterContentInit() {
        if (this.focusModel.centerScale == null) {
            this.focusModel.setCenterScale(new CenterScale(0, 6707108, 6933504.261314690));
        }
        
        this.mapControllerEnv = {
            boundsModel: this.boundsModel,
            focusModel: this.focusModel,
            mouseWheelAction: this.mouseWheelAction,
            tapFunction: this.tapFunction,
            pressFunction: this.pressFunction,
            releaseFunction: this.releaseFunction,
            mouseMoveFunction: this.mouseMoveFunction
        };
        this.mapController = new MapController(this.hostRef.nativeElement, this.mapControllerEnv);
        
        this.contentChildren.forEach(contentChild => contentChild.init(this.boundsModel, this.focusModel, this.envelopeModel));
    }
    
    ngDoCheck(): void {
        var bounds = new Bounds(this.hostRef.nativeElement.clientWidth, this.hostRef.nativeElement.clientHeight);
        if (!bounds.equals(this.boundsModel.bounds)) {
            this.boundsModel.setBounds(bounds);
        }
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if (this.mapControllerEnv != null) {
            if (changes.mouseWheelAction != null) {
                this.mapControllerEnv.mouseWheelAction = this.mouseWheelAction;
            }
            if (changes.tapFunction != null) {
                this.mapControllerEnv.tapFunction = this.tapFunction;
            }
            if (changes.pressFunction != null) {
                this.mapControllerEnv.pressFunction = this.pressFunction;
            }
            if (changes.releaseFunction != null) {
                this.mapControllerEnv.releaseFunction = this.releaseFunction;
            }
            if (changes.mouseMoveFunction != null) {
                this.mapControllerEnv.mouseMoveFunction = this.mouseMoveFunction;
            }
        }
    }
}
