declare var Bounds: any;
declare var BoundsModel: any;
declare var EnvelopeModel: any;
declare var FocusModel: any;
declare var Layer: any;

import { Injectable, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NineyDefaultService {
    heartbeatTimer;
    windowBoundsModel;
    defaultBoundsModel;
    defaultFocusModel;
    defaultEnvelopeModel;
    defaultTilesLayer;

    constructor(private zone: NgZone) {
        /*var timer = new Timer(2000, -1);
        timer.scope = null;
        timer.timerHandler = function() {
            for (var i = 0; i < timer.subTimerHandlers.length; i++) {
                timer.subTimerHandlers[i].apply();
            }
        };
        timer.subTimerHandlers = [];
        timer.addSubTimerHandler = function(subTimerHandler) {
            timer.subTimerHandlers.push(subTimerHandler);
        };
        timer.removeSubTimerHandler = function(subTimerHandler) {
            for (var i = 0; i < timer.subTimerHandlers.length; i++) {
                if (timer.subTimerHandlers[i] == subTimerHandler) {
                    timer.subTimerHandlers.splice(i, 1);
                    return;
                }
            }
        };
        timer.start();
        this.heartbeatTimer = timer;*/

        var boundsModel = new BoundsModel();
        boundsModel.setBounds(new Bounds(window.innerWidth, window.innerHeight));
        window.addEventListener("resize", function(resizeEvent) {
            boundsModel.setBounds(new Bounds(window.innerWidth, window.innerHeight));
        });
        this.windowBoundsModel = boundsModel;
        this.defaultBoundsModel = new BoundsModel();

        this.defaultFocusModel = new FocusModel();
        this.defaultEnvelopeModel = new EnvelopeModel(this.defaultBoundsModel, this.defaultFocusModel);
        
        this.defaultTilesLayer = new Layer("Tiles");
    }
}
