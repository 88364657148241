export class Departure {
    
    constructor(
        public tripId: number,
        public departureTime: string,
        public tripHeadsign: string,
        public agencyId:  string,
        public routeShortName:  string,
        public routeLongName:  string,
        public extraTrip?: boolean
    ) { }

    public static parseDeparture(response: any): Departure {  
        return new Departure(
            response.tripId, 
            response.departureTime, 
            response.tripHeadsign, 
            response.agencyId,
            response.routeShortName, 
            response.routeLongName
            ); 
    }

}

