import { Component, DoCheck } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageModelService } from '../language-model.service';
import { ScenarioModelService } from '../scenario-model.service';
import { SelectionModelService } from '../selection-model.service';
import { ToolModelService } from '../tool-model.service';

@Component({
  selector: 'app-benchmark-table',
  templateUrl: './benchmark-table.component.html',
  styleUrls: ['./benchmark-table.component.scss']
})
export class BenchmarkTableComponent implements DoCheck {
  urlObject = {
    AFSTANDBRON: null,
    RICHTING: null,
    LEVEL: null,
    SEL: ""
  };
  speedBenchmarks;
  distanceBenchmarks;
  totalBenchmarks;
  durationsPerDistance;
  longDurationsPerDistance;
  message;

  constructor(private http: HttpClient, private l: LanguageModelService, private scenarioModel: ScenarioModelService, public toolModel: ToolModelService, private selectionModel: SelectionModelService) { }

  ngDoCheck() {
    if (
      ((this.toolModel.params != null) && (
        (this.urlObject.AFSTANDBRON != this.toolModel.params["AFSTANDBRON"].option.value) ||
        (this.urlObject.RICHTING != this.toolModel.params["RICHTING"].option.value) ||
        (this.urlObject.LEVEL != this.toolModel.params["LEVEL"].option.value) ||
        (this.urlObject.SEL != this.selectionModel.getAreaIds())
      ))
    ) {
      this.loadData();
    }
  }

  loadData() {
    this.urlObject = {
      LEVEL: this.toolModel.params["LEVEL"].option.value,
      RICHTING: this.toolModel.params["RICHTING"].option.value,
      AFSTANDBRON: this.toolModel.params["AFSTANDBRON"].option.value,
      SEL: this.selectionModel.getAreaIds()
    }
    let url = "http://app.movemeter.com/php/get-bereikbaarheidsindicator-data-json.php?indicator_id=7291&level=" + this.urlObject.LEVEL + "&richting=" + this.urlObject.RICHTING + "&afstandbron=" + this.urlObject.AFSTANDBRON + "&isochronen=0&dartbordverschil=0&sel=" + this.urlObject.SEL;
    this.http.get(url)
      .subscribe(
        response => {
          this.speedBenchmarks = response["speedBenchmarks"];
          this.distanceBenchmarks = response["distanceBenchmarks"];
          this.totalBenchmarks = response["totalBenchmarks"];
          this.durationsPerDistance = response["durationsPerDistance"];
          this.longDurationsPerDistance = response["longDurationsPerDistance"];
          this.message = response["message"];
        }
      );
  }
}
