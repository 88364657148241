declare var TileModel: any;

import { Component, Input, forwardRef } from '@angular/core';
import { CanvasMapLayer } from "../map/canvas-map-layer";
import { MapLayer } from "../map/map-layer";
import { NineyDefaultService } from '../niney-default.service';

@Component({
  selector: 'tileslayer',
  templateUrl: './tiles-layer.component.html',
  styleUrls: ['./tiles-layer.component.scss'],
  providers: [{provide: MapLayer, useExisting: forwardRef(() => TilesLayerComponent)}]
})
export class TilesLayerComponent extends CanvasMapLayer {
  private tileModel;
  
  @Input() loader;

  constructor(private nineyDefaultService: NineyDefaultService) {
    super();

    this.layer = this.nineyDefaultService.defaultTilesLayer;
  }

  init(boundsModel, focusModel, envelopeModel) {
    super.init(boundsModel, focusModel, envelopeModel);

    this.tileModel = new TileModel();
    this.tileModel.ctx = this.canvasRef.nativeElement.getContext('2d');
    this.tileModel.srs = this.focusModel.srs;
    this.tileModel.loader = this.loader;
    this.tileModel.setBounds(this.boundsModel.bounds);
    this.tileModel.setCenterScale(this.focusModel.animationCenterScale);
    setTimeout(() => this.tileModel.setLayer(this.layer));
  }

  ngDoCheck(): void {
    super.ngDoCheck();

    if (this.tileModel == null) {
      return;
    }

    if ((this.boundsModel != null) && (this.tileModel.bounds != this.boundsModel.bounds)) {
      this.tileModel.setBounds(this.boundsModel.bounds, this.envelopeModel.getEnvelope(), this.envelopeModel.getAnimationEnvelope());
    }
    if ((this.focusModel != null) && (this.tileModel.centerScale != this.focusModel.centerScale)) {
      this.tileModel.setCenterScale(this.focusModel.centerScale, this.envelopeModel.getEnvelope());
    }
    if ((this.focusModel != null) && (this.tileModel.animationCenterScale != this.focusModel.animationCenterScale)) {
      this.tileModel.setAnimationCenterScale(this.focusModel.animationCenterScale, this.envelopeModel.getAnimationEnvelope());
    }
  }
}
