import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class LanguageModelService {
    languages = [
        { name: "English", code: "en" },
        { name: "Nederlands", code: "nl" },
        /*  {name: "Deutsch", code: "de"},
          {name: "Dansk", code: "dk"},
          {name: "Español", code: "es"},
          {name: "Italiano", code: "it"}*/
    ];
    language;
    messages;
    m;
    
    constructor(private http: HttpClient) {
        this.loadMessages();
    }
    
    setLanguage(language) {
        this.language = language;
        this.m = this.messages[language.code];
    }
    
    translate(text) {
        return this.t(text);
    }
    
    t(text) {
        text.replace(/~(.*?)~/g, (match) => text = text.replace(match, this.m[match]));
        return text;
    }
    
    private loadMessages() {
        this.http.get("assets/localized-strings.json").subscribe(
            response => {
                this.messages = response;
                this.setLanguage(this.languages[1]);
            }
        );
    }
}
