import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CustomComponent } from "../custom.component"; 
import { environment } from "../../../../environments/environment";
import { HttpClient, HttpEventType, HttpResponse, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http'; 
import { Observable } from "rxjs";


@Component({
  selector: 'app-import-socio',
  templateUrl: './import-socio.component.html',
  styleUrls: ['./import-socio.component.scss']
})
export class ImportSocioComponent extends CustomComponent implements OnInit {

  public urlUpload = environment.serverUrl + "/rest/import-sociodata";
  public files;
  public modelConcentration;
  public inhabitantsConcentrationField;
  public jobsConcentrationField;
  public modelSpread;
  public inhabitantsSpreadField;
  public jobsSpreadField; 

  constructor(private http: HttpClient) {
    super();
  }

  ngOnInit() {
  }

  loading: boolean = false;
  message: string = "";
  @ViewChild('fileInput') fileInput: ElementRef;
 
  onSubmit() {
    console.log("onSubmit"); 
    if (this.files.length == 0) {
      this.setMessage("Geen bestand geselecteerd");
      return
    }
    let file: File = this.files[0];
    this.loading = true;
    this.uploadFile(file)
      .subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round(10000 * event.loaded / event.total) / 100;
            this.setMessage("Bezig met upload, " + percentDone + " % klaar.");
          } else if (event instanceof HttpResponse) {
            this.setMessage('Bestand is volledig geupload en wordt nu op de server verwerkt.');
          }
        },
        (err) => {
          // console.log("Upload Error:", err);
        }, () => {
          // console.log("Upload done");
        }
      )
  }

  fileChange(event) {
    console.log("fileChange"); 
    this.files =  event.target.files;
  }

  uploadFile(file): Observable<HttpEvent<any>> {

    let formData = new FormData(); 
    formData.append('upload', file);
    formData.append('modelConcentration', this.modelConcentration);
    formData.append('inhabitantsConcentrationField', this.inhabitantsConcentrationField);
    formData.append('jobsConcentrationField', this.jobsConcentrationField);
    formData.append('modelSpread', this.modelSpread);
    formData.append('inhabitantsSpreadField', this.inhabitantsSpreadField);
    formData.append('jobsSpreadField', this.jobsSpreadField); 

    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', environment.serverUrl + "/rest/upload/socio", formData, options);
    return this.http.request(req);
  }

  setMessage(message: string):void {
      this.message = message;
  }  

}
