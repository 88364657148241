import { Component, OnInit, DoCheck } from '@angular/core';
import { CustomComponent } from "../custom.component"; 
import { EmptyModelService } from "../../../empty-model.service";
import { NineyDefaultService } from "../../../niney-default.service";
import { HttpClient } from "@angular/common/http";
import { ScenarioModelService } from "../../../scenario-model.service";
import { GenericMeasureService } from './generic-measure.service';


@Component({
  selector: 'app-generic-measure',
  templateUrl: './generic-measure.component.html',
  styleUrls: ['./generic-measure.component.scss']
})
export class GenericMeasureComponent extends CustomComponent implements OnInit, DoCheck {

  constructor(
    public emptyModel: EmptyModelService, 
    private nineyDefaultService: NineyDefaultService, 
    private http: HttpClient, 
    private scenarioModel: ScenarioModelService,
    public genericMeasureService: GenericMeasureService) {
    super();
   }


  public drawedGeometry; 


  ngDoCheck() {
    if (this.emptyModel.drawedGeometry != this.drawedGeometry) {
      this.drawedGeometry = this.emptyModel.drawedGeometry;
      this.geometryEvent();
    }
  }

  ngOnInit() {
  }

  toClipboard() {
    let te = "";
    for (let measure of this.genericMeasureService.measures) {
      te += measure.asText() + "\n";
    } 
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = te;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  save() {
    // TODO
  }




  geometryEvent() { 
    if (this.genericMeasureService.actualMeasure == null) { 
      return;   // no measure active -> ignore click or polygon
    }
    if (this.drawedGeometry.indexOf("POINT") !=-1) {
      this.click();
    }
    if (this.drawedGeometry.indexOf("POLYGON") !=-1) { 
        this.genericMeasureService.actualMeasure.wktArea = this.drawedGeometry;
    }    
  }

  click() {
    let pointArr = this.trim(this.trim(this.drawedGeometry, "POINT("), ")").split(" ");
    const scale = this.nineyDefaultService.defaultFocusModel.centerScale.getNumWorldCoords(1);
        const url = "http://app.movemeter.com/php/mapserver/getInfo.php?format=json&Gebiednr=" + this.scenarioModel.scenario.id + "&Scenarionr=" + this.scenarioModel.scenario.id + "&hbanalyse_id=0&x=" + pointArr[0] + "&y=" + pointArr[1] + "&zoom=10&scale=" + scale + "&extent=562716.94177178,6918380.2619977,563620.45133256,6918739.7548963&datatype=pol&aktKaart=snelheden&rechten=2&step=2";
        this.http.get(url).subscribe(
            response => {
                if (response["segments"].length == 0) {
                } else {
                    this.genericMeasureService.adaptInClause(response["segments"][0].id.split("-")[1]);
                }
            }
        );
  }

 
  private trim (s, c) {
    if (c === "]") c = "\\]";
    if (c === "\\") c = "\\\\";
    return s.replace(new RegExp(
      "^[" + c + "]+|[" + c + "]+$", "g"
    ), "");
  }
 
}
