export class ReferenceLineParams {

    constructor(public a: number, public b: number, public c: number, public d: number, public grens1: number, public grens2: number) { }

    checksum(): number {
        return this.a + 12.3882 * this.b + 293.3442 * this.c + 2883.4432 * this.d + 88394.22345 * this.grens1 + 394827.3321 * this.grens2;
    }
 
    // geeft reistijd in minuten, volgens referentielijn, voor afstand in kilometer
    getReferentieReistijd(km: number): number { 
        return  60 * (this.a + this.b * km)
             * ((1 - Math.exp(this.c * km + this.d)) / 
                (1 + Math.exp(this.c * km + this.d)));
    }
     
}
