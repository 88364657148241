export class Scenario {
    
    constructor(
        public id: number,
        public name: string,
        public parent?: number,
        public basedOnId?: number,
        public odMatrixSourceSubmodelId?: number,
        public networkSpeedsSourceSubmodelId?: number,
        public dayPart?: string,
        public dayPartOriginalHours?: number,
        public x?: number,
        public y?: number,
        public foreignCountry?: boolean,
        public modelSpeedType?: string,
        public networkType?: string,
        public area?: number,
        public selectedZones?: number[],
        public scenarioId?: number,
        public assignmentFraction?: number,
        public calculationType?: number,
        public selectedLink?: number,
        public fietsnetNetworkId?: number,
        public skimOvStopSetId?: number,
        public skimOvModelId?: number,
        public skimnummerPublicTransportId?: number,
        public odMatrixPublicTransportId?: number,
        public odMatrixBicyleId?: number,
        public progressType?: string,
        public accessibilityIndicatorVariants? ,
        public calculated?: boolean,
        public administrativeArea?: number,
        public referenceLine?: string,
        public measures: any[] = [],
        public measureScenarios: Scenario[] = []
    ) { }
}
