import { Component, ContentChildren, Input, forwardRef } from "@angular/core";
import { MapLayer } from "../map/map-layer";
import { Symbolizer } from "./symbolizer";

@Component({
    selector: "mapfeaturelayer",
    templateUrl: "./map-feature-layer.component.html",
    styleUrls: ["./map-feature-layer.component.scss"],
    providers: [{ provide: MapLayer, useExisting: forwardRef(() => MapFeatureLayerComponent) }]
})
export class MapFeatureLayerComponent extends MapLayer {
    @ContentChildren(Symbolizer) private contentChildren;
    
    @Input("featuremodel") featureModel;
    
    init(boundsModel, focusModel, envelopeModel) {
        super.init(boundsModel, focusModel, envelopeModel);
        
        this.contentChildren.forEach(contentChild => contentChild.init(boundsModel, focusModel, envelopeModel, this.featureModel));
    }
}
