declare var WMSModel: any;

import { Component, DoCheck, Input, forwardRef } from "@angular/core";
import { MapLayer } from "../map/map-layer";

@Component({
    selector: "wmslayer",
    templateUrl: "./wms-layer.component.html",
    styleUrls: ["./wms-layer.component.scss"],
    providers: [{ provide: MapLayer, useExisting: forwardRef(() => WMSLayerComponent) }]
})
export class WMSLayerComponent extends MapLayer implements DoCheck {
    wmsModel = new WMSModel();
    visible = true;
    
    @Input() loader = null;
    
    init(boundsModel, focusModel, envelopeModel) {
        super.init(boundsModel, focusModel, envelopeModel);
        
        this.wmsModel.srs = this.focusModel.srs;
        this.wmsModel.loader = this.loader;
        this.wmsModel.setBounds(this.boundsModel.bounds);
        setTimeout(() => {
            this.wmsModel.setCenterScale(this.focusModel.incubationCenterScale);
            this.wmsModel.setAnimationCenterScale(this.focusModel.animationCenterScale);
            this.wmsModel.setLayer(this.layer);
        });
    }
    
    ngDoCheck(): void {
        if ((this.boundsModel != null) && (this.wmsModel.bounds != this.boundsModel.bounds)) {
            this.wmsModel.setBounds(this.boundsModel.bounds);
        }
        if ((this.focusModel != null) && (this.wmsModel.centerScale != this.focusModel.incubationCenterScale)) {
            this.wmsModel.setCenterScale(this.focusModel.incubationCenterScale);
        }
        if ((this.focusModel != null) && (this.wmsModel.animationCenterScale != this.focusModel.animationCenterScale)) {
            this.wmsModel.setAnimationCenterScale(this.focusModel.animationCenterScale);
        }
        
        if (this.layer != null) {
            if (this.wmsModel.layer != this.layer) {
                this.wmsModel.setLayer(this.layer);
                this.visible = this.layer.visible;
            } else if (this.visible != this.layer.visible) {
                this.wmsModel.load();
                this.visible = this.layer.visible;
            }
        }
    }
}
