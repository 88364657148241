import { Component } from "@angular/core";
import { Scenario } from "../scenario";
import { ScenarioModelService } from "../scenario-model.service";
import { ToolModelService } from "../tool-model.service";
import { WindowModelService } from "../window-model.service";

@Component({
    selector: "app-new-measure-scenario",
    templateUrl: "./new-measure-scenario.component.html",
    styleUrls: ["./new-measure-scenario.component.scss"]
})
export class NewMeasureScenarioComponent {
    scenarioName = "";
    
    constructor(private windowModel: WindowModelService, public scenarioModel: ScenarioModelService, private toolModel: ToolModelService) { }
    
    addScenario() {
        const measureScenario: Scenario = new Scenario(12345, this.scenarioName);
        this.scenarioModel.scenario.measureScenarios.push(measureScenario);
        this.scenarioModel.measureScenario = measureScenario;
        
        this.windowModel.setPopupVisible(6, false);
        
        setTimeout(() => {
            this.toolModel.mode = 2;
            this.windowModel.toggleMenu("tool");
        });
    }
}
