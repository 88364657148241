export class TimeFunctions {
    
    public static timeOffsetMinutes(baseTime: string, offsetMinutes: number): string {
        return TimeFunctions.timeOffsetSeconds(baseTime, offsetMinutes * 60);
    }
    
    public static timeOffsetSeconds(baseTime: string, offsetSeconds: number): string {
        // baseTime: format h:mm:ss or h:mm, example 7:08:12 or 7:08
        // did not use Date-object because this is local aware time-zone dependent, 24/12h time will work differently on different browsers en locations
        // libraries to solve this would make application more heavy than this tiny method...
    
        let splitArray = baseTime.split(":");
        let hours = parseInt(splitArray[0]);
        let minutes = parseInt(splitArray[1]);
        let seconds = 0; if (splitArray[2]) seconds = parseInt(splitArray[2]);
        
        seconds += offsetSeconds; 
        let remainingSeconds = seconds % 60;
        if (remainingSeconds < 0) remainingSeconds += 60;
        minutes += Math.round((seconds - remainingSeconds) / 60);
        seconds = remainingSeconds;
    
        let remainingMinutes = minutes % 60;
        if (remainingMinutes < 0) remainingMinutes += 60;
        hours += Math.round((minutes - remainingMinutes) / 60);
        minutes = remainingMinutes;
        
        return TimeFunctions.fill00(hours) + ":" + TimeFunctions.fill00(minutes) + ":" + TimeFunctions.fill00(seconds);
    }
    
    public static getTimeDifferenceSeconds(time1, time2): number {
        let splitArray = time1.split(":");
        let hours1 = parseInt(splitArray[0]);
        let minutes1 = parseInt(splitArray[1]);
        let seconds1 = 0; if (splitArray[2]) seconds1 = parseInt(splitArray[2]);
     
        splitArray = time2.split(":");
        let hours2 = parseInt(splitArray[0]);
        let minutes2 = parseInt(splitArray[1]);
        let seconds2 = 0; if (splitArray[2]) seconds2 = parseInt(splitArray[2]);    
        
        let differenceSeconds = (hours1 * 3600 + minutes1 * 60 + seconds1 - hours2 * 3600 - minutes2 * 60 - seconds2); 
        return differenceSeconds;
    }
      
    public static getTimeDifferenceMinutes(time1, time2): number {
        return TimeFunctions.getTimeDifferenceSeconds(time1, time2) / 60;
    }
    
    public static fill00(num:number):string {
        let formated = num.toString();
        if (formated.length < 2) formated = "0" + formated;
        return formated;
    }
    
    public static formatTimeHMM(time: string): string {
        if (!time) return "-";
        let splitArray = time.split(":");
        let hours = splitArray[0];
        let minutes = splitArray[1]; 
        return hours + ":" + minutes;  
    }

}

