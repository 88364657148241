declare var WKTConverter: any;
declare var Feature: any; 
declare var Point: any;
declare var Polygon: any;

import { Component, OnInit, DoCheck } from '@angular/core';
import { SourceModelService } from "../source-model.service";
import { ReferenceLineModelService } from "../reference-line-model.service";
import { WindowModelService } from '../window-model.service'; 
import { SelectionPolygonControllerService } from '../selection-polygon-controller.service'; 
import { ScenarioModelService } from '../scenario-model.service'; 
import { UserModelService } from '../user-model.service'; 
import { HttpClient } from '@angular/common/http';
import { Scenario }   from '../scenario';
import { AggregatedAreaModelService } from '../aggregated-area-model.service';
import { environment }   from '../../environments/environment'; 

@Component({
  selector: 'app-new-base-scenario',
  templateUrl: './new-base-scenario.component.html',
  styleUrls: ['./new-base-scenario.component.scss']
})
 
export class NewBaseScenarioComponent implements OnInit, DoCheck {

    constructor(
        public userModel: UserModelService,
        public sourceModel: SourceModelService,
        public scenarioModel: ScenarioModelService,
        public windowModel: WindowModelService,
        public referenceLineModel: ReferenceLineModelService,
        public selectionPolygonController: SelectionPolygonControllerService,
        private aggAreaModel: AggregatedAreaModelService,
        private http: HttpClient
    ) { }
  nieuwScenario: Scenario; 
  odCar: number = 0;  
  networkCar: number = 0;
  odCarChecked: number = 0;
  networkCarChecked: number = 0;
  skimInCache: boolean;
  bbiEnabled: boolean = true;
  invalidFormData: string = ""; 
  totaalZones: string = "250";
  studieZones: string = "150";
  freightSelected: boolean = false;
  wktForReferencelineCalculation: string = "";
  calculateReferenceLineOtherArea = true;
  referenceLineModality: number = 0;
  bbiChoice:any[][]=[[0,0,0,0,0,0,0,0],[0,0,0,0,0,0,0,0]];
  areaValid = false;
  baseScenarioName = "";

  ngOnInit() {
    this.sourceModel.init();
  }

  ngDoCheck(): void  { 
    this.checkOdCar();
    this.checkNetworkCar();
    this.checkBbiType();
    this.checkReferenceLine();
    this.processDrawedPolygon(this.selectionPolygonController.geometry);
  }

  checkOdCar() {
    if (!this.odCar || this.odCarChecked == this.odCar) return;
    this.odCarChecked = this.odCar; 
    this.sourceModel.loadPtMatricesByOdCar(this.odCar);
    this.checkCarSourceModelSkimInCacheForNewBaseScenario();
  }

  checkNetworkCar() {  
    if (!this.networkCar || this.networkCarChecked == this.networkCar) return;
    this.networkCarChecked = this.networkCar; 
    this.checkCarSourceModelSkimInCacheForNewBaseScenario();
  }
 
  checkBbiType() {
    for (let i:number = 0; i < 2; i++) { 
      if (this.modalities[i].bbiTypeChecked == this.modalities[i].bbiType) continue;
      this.modalities[i].bbiTypeChecked = this.modalities[i].bbiType;
      if (this.modalities[i].id == "CAR") { 
        this.checkCarSourceModelSkimInCacheForNewBaseScenario(); 
      }
      if (this.modalities[i].bbiType == 6) {
        this.selectionPolygonController.setGeometry();
      } else {
        this.selectionPolygonController.resetGeometry();
      }
    }
  }

  checkReferenceLine() {
    let referenceLine = "";
    if (this.modalities[this.referenceLineModality].bbiType > 1) {
      referenceLine = this.bbiChoice[this.referenceLineModality][this.modalities[this.referenceLineModality].bbiType];
    }
    this.referenceLineModel.initWithBbiString(referenceLine);  
  }
  
  step: number = 1; 
  aggregatedAreasResponse: any;
  aggregationPreviewMessage: string = "";
 
  modalities: any[] = [
        {
            id: "CAR",
            name: "auto/vracht",
            disabled: true,
            selected: true,
            hasBenchmark: true,
            bbiType: 2
        }, {
            id: "TRANSIT",
            name: "openbaar vervoer",
            disabled: false,
            selected: false,
            hasBenchmark: true,
            bbiType: 1
        }, {
            id: "BIKE",
            name: "fiets",
            disabled: false,
            selected: false,
            hasBenchmark: false
            // bicycle has no bbiType
        }
    ];
 
  public referentielijnTextChange(event): void {  
    this.referenceLineModel.initWithBbiString(event.target.value);
  }

  bbiReferentielijnChange(event): void {
    this.referenceLineModel.initWithBbiString(event.target.value);
  }

  totaalZonesChange(value) {
    this.totaalZones = value;
  }
  
  studieZonesChange(value) {
    this.studieZones = value;
  }

  showReferentielijn(modality: number): void {
    this.referenceLineModality = modality;
    this.windowModel.popups[2].visible=true; 
  }

  incStep() {
    if (!this.validateInvoer()) return;
    this.step++;
    this.stepChanged();
  }

  decStep() {
    if (!this.validateInvoer()) return;    
    this.step--;
    this.stepChanged();
  }

  stepChanged() {
    this.aggregationPreviewMessage = "";
    switch(this.step) {
      case 1:
        this.selectionPolygonController.resetGeometry();
        break;
      case 2:
        this.selectionPolygonController.setGeometry();
        break;
      case 3:
        this.startCalculation()
        break;
    }
  } 
  
  getSourceModelHbMatrixName() {
    if (this.odCar) {
      for (let i=0; i < this.sourceModel.sourceOdMatrices.length; i++) {
        if (this.odCar == this.sourceModel.sourceOdMatrices[i]['id']) {
          return this.sourceModel.sourceOdMatrices[i]['name'];
        }
      }
    } 
    return "nog geen hb gekozen";
  }

  getSourceModelNetwork() { 
    if (this.networkCar) {
      for (let i=0; i < this.sourceModel.sourceNetwerken.length; i++) {
        if (this.networkCar == this.sourceModel.sourceNetwerken[i]['id']) {
          return this.sourceModel.sourceNetwerken[i]['name'];
        }
      }
    } 
    return "nog geen netwerk gekozen";
  } 

  validateInvoer(): boolean {
    if (!this.odCar) {
      this.skimInCache = true;
      this.invalidFormData = "Kies eerst een geldige hb matrix auto alvorens verder te gaan";
      return false;
    }
    
    if (!this.networkCar) {
      this.skimInCache = true;
      this.invalidFormData = "Kies eerst geldige netwerk en snelheden auto alvorens verder te gaan";
      return false;
    }
    this.invalidFormData = "";
    return true;
  }

  checkCarSourceModelSkimInCacheForNewBaseScenario() {
    
    if (!this.validateInvoer()) return;    
    if (!this.odCar || !this.networkCar) return;
 
    this.http.get(environment.serverUrl + "/rest/area/isCarSourceModelSkimInCacheForNewBaseScenarioV2/" + this.odCar + "/" + this.networkCar + "/" + this.bbiEnabled)
    .subscribe(
      (response: any) => {
        if (response == 'true'|| response == true) {
          this.skimInCache = true;
        } else if (response == 'false' || response == false) {
          this.skimInCache = false;
        } else {
          console.log("invalid answer on isCarSourceModelSkimInCacheForNewBaseScenarioV2", response);
          this.skimInCache = true;
        }
      }
    );    
  }

  startCalculation() {
    let referenceLineCar = this.bbiChoice[0][this.modalities[0].bbiType];
    let referenceLinePt = this.bbiChoice[1][this.modalities[1].bbiType];
    let referenceLineTruck = "";  
    if (this.freightSelected) {
      referenceLineTruck = referenceLineCar;  
    }

    // TODO: check if referenceLines are valid
    // TODO: check if baseScenarioName has to be encoded (or Angular does that)
    // TODO: also give values of fietsnet_network_id, skimov_stop_set_id etc (no change in code gwt)
    let url = environment.serverUrl + "/rest/route?Berekening=6" +
                "&wkt=" + this.wktForReferencelineCalculation +
                "&naam=" + this.baseScenarioName +                
                "&bronsnelheden=" + this.networkCar +
                "&bronhbmatrix=" + this.odCar +
                "&bronbestuurlijkgebied=-1" +
                "&referenceline_car=" + referenceLineCar +
                "&referenceline_pt=" + referenceLinePt +
                "&referenceline_truck=" + referenceLineTruck +
                "&user_id=" + this.userModel.getUserId() +
                "&zonesstudiegebied=" + this.studieZones +
                "&zonestotaal=" + this.totaalZones + 
                "&fietsnet_network_id=" +
                "&skimov_stop_set_id=" +
                "&skimnummer_public_transport_id=" +
                "&od_matrix_public_transport_id=" +
                "&od_matrix_bicycle_id=" +
                "&preview=0";  
      this.http.get(url)
          .subscribe(
            (response: any) => {
              this.aggregatedAreasResponse = response; 
              let features = [];
              let number: number = 0;
              let numberGreen: number = 0;
              for (let zone of response.zones) {
                number++;
                if (zone.type == 0) numberGreen++;
                let feature = new Feature(this.aggAreaModel.featureType, [new Point(zone.x, zone.y), zone.type]);
                features.push(feature);
              }
              this.aggAreaModel.features = features; 
              this.aggregationPreviewMessage = "Studiegebied ingetekend. Dit heeft geleid tot<br>" + 
                  numberGreen + " aantal niet geaggregeerde (groene) zones<br>" + 
                  number + " totaal aantal zones<br>" +
                  "U kunt nog een keer proberen het studiegebied (anders) in te tekenen of verdergaan door een naam te kiezen van deze uitgangssituatie"; 
              this.selectionPolygonController.setGeometry();
              this.areaValid = (number > 1);
            }
          );

  }

  processDrawedPolygon(linestring) { 
    if (!linestring) return;  
    let polygon = new Polygon([linestring]); 
    let wktConverter = new WKTConverter();
    let wkt:string = wktConverter.geometryToWKT(polygon); 

    if (this.step == 1) {
      
      this.wktForReferencelineCalculation = wkt;
      this.selectionPolygonController.setGeometry();
    } else {

    let url = environment.serverUrl + "/route?Berekening=6" +
                "&wkt=" + wkt +
                "&bronsnelheden=" + this.networkCar +
                "&bronhbmatrix=" + this.odCar +
                "&user_id=" + this.userModel.getUserId() +
                "&zonesstudiegebied=" + this.studieZones +
                "&zonestotaal=" + this.totaalZones + 
                "&preview=1";  
      this.http.get(url)
          .subscribe(
            (response: any) => {
              this.aggregatedAreasResponse = response; 
              let features = [];
              let number: number = 0;
              let numberGreen: number = 0;
              for (let zone of response.zones) {
                number++;
                if (zone.type == 0) numberGreen++;
                let feature = new Feature(this.aggAreaModel.featureType, [new Point(zone.x, zone.y), zone.type]);
                features.push(feature);
              }
              this.aggAreaModel.features = features; 
              this.aggregationPreviewMessage = "Studiegebied ingetekend. Dit heeft geleid tot<br>" + 
                  numberGreen + " aantal niet geaggregeerde (groene) zones<br>" + 
                  number + " totaal aantal zones<br>" +
                  "U kunt nog een keer proberen het studiegebied (anders) in te tekenen of verdergaan door een naam te kiezen van deze uitgangssituatie"; 
              this.selectionPolygonController.setGeometry();
              this.areaValid = (number > 1);
            }
          );
      }

  }


}
