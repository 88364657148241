import { Component, DoCheck } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AggregatedAreaModelService } from "../../../aggregated-area-model.service";
import { CustomComponent } from "../custom.component";
import { LanguageModelService } from "../../../language-model.service";
import { ScenarioModelService } from "../../../scenario-model.service";
import { ToolModelService } from "../../../tool-model.service";
import { environment }   from '../../../../environments/environment'; 

@Component({
    selector: "app-od-relations-tool",
    templateUrl: "./od-relations-tool.component.html",
    styleUrls: ["./od-relations-tool.component.scss"]
})
export class OdRelationsToolComponent extends CustomComponent implements DoCheck {
    private aggregatedAreas;
    selectedAggregatedAreas = [];
    
    constructor(private http: HttpClient, public l:LanguageModelService, private scenarioModel:ScenarioModelService, private toolModel:ToolModelService, private aggregatedAreaModel:AggregatedAreaModelService) {
        super();
    }
    
    ngDoCheck() {
        if (this.aggregatedAreas != this.aggregatedAreaModel.features) {
            this.aggregatedAreas = this.aggregatedAreaModel.features;
            this.setSelectedAggregatedAreas();
        }
    }
    
    setSelectedAggregatedAreas() {
        if (this.aggregatedAreas == null) {
            return;
        }
        
        const selectedAggregatedAreas = [];
        for (let aggregatedArea of this.aggregatedAreas) {
            if (aggregatedArea.propertyValues[2] == 1) {
                selectedAggregatedAreas.push({
                    areaId: aggregatedArea.propertyValues[0],
                    areaType: this.toolModel.params["AGGREGATIEGEBIEDEN"].option.value,
                    coordX: aggregatedArea.propertyValues[3].x,
                    coordY: aggregatedArea.propertyValues[3].y,
                });
            }
        }
        this.selectedAggregatedAreas = selectedAggregatedAreas;
    }
    
    calculate() {
        const requestObject = {
            scenarioId: this.scenarioModel.scenario.id,
            selection: {
                aggregatedAreaType: this.toolModel.params["AGGREGATIEGEBIEDEN"].option.value,
                gridSize: this.toolModel.params["AGGREGATIEGEBIEDEN"].options[3].params[0].options[0].value
            },
            areas: this.selectedAggregatedAreas,
            filter: {
                modality: 0,
                minTrips: 0,
                maxTrips: 2147483647,
                minDistance: 0,
                maxDistance: 2147483647,
            }
        }
        const url = environment.serverUrl + "/rest/od-analysis/";
        this.http.post(url, requestObject).subscribe(
            response => { this.tool.params[2].option = { name: "", value: response }; }
        );
    }
}
