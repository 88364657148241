import { Component, DoCheck } from '@angular/core';
import { LanguageModelService } from './language-model.service';
import { SelectionModelService } from './selection-model.service';
import { ToolModelService } from './tool-model.service';
import { WindowModelService } from './window-model.service';
import { UserModelService } from './user-model.service';
import { ScenarioModelService } from './scenario-model.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements DoCheck {
    username = "";
    password = "";
  numSegments = 0;
  message;
  expertMode = false;

    constructor(
        public windowModel: WindowModelService,
        public l: LanguageModelService,
        public userModel: UserModelService,
        public scenarioModel: ScenarioModelService,
        public toolModel: ToolModelService,
        public selectionModel: SelectionModelService
    ) { }
  
  ngDoCheck() {
    if (
      (this.numSegments != this.selectionModel.segments.length) ||
      (this.message != this.selectionModel.message)
    ) {
      this.numSegments = this.selectionModel.segments.length;
      this.message = this.selectionModel.message;
        
      this.windowModel.setPopupVisible(0, (this.selectionModel.segments.length > 0) || (this.message != null));
    }
  }
    
    login() {
        if ((this.username != "") && (this.password != "")) {
            this.userModel.loadUser(this.username, this.password);
            this.username = "";
            this.password = "";
        }
    }
}
