declare var CenterScale: any;
declare var FocusModel: any;
declare var Layer: any;
declare var LineString: any;
declare var Loader: any;
declare var Point: any;
declare var Polygon: any;

import { Component, DoCheck, Input } from '@angular/core';
import { AggregatedAreaModelService } from '../aggregated-area-model.service';
import { LanguageModelService } from '../language-model.service';
import { NineyDefaultService } from '../niney-default.service';
import { PtLineModelService } from "../pt-line-model.service";
import { PtStopModelService } from "../pt-stop-model.service";
import { SelectionModelService } from '../selection-model.service';
import { ToolModelService } from '../tool-model.service'; 
import { GtfsModelService } from '../tool-panel/custom/gtfs/gtfs-model.service';
import { SelectionPolygonControllerService } from '../selection-polygon-controller.service';
import { EmptyModelService } from '../empty-model.service';

@Component({
  selector: 'app-single-map',
  templateUrl: './single-map.component.html',
  styleUrls: ['./single-map.component.scss']
})
export class SingleMapComponent implements DoCheck {
  private defaultFocusModel;
  private tool;
  private toolOptions = [];
  @Input() scenario;
  @Input() dual;
  wmsLayer;
  loader = new Loader();
  featureModels;

    constructor(
        private nineyDefaultService: NineyDefaultService,
        public l: LanguageModelService,
        public toolModel: ToolModelService,
        public selectionModel: SelectionModelService, 
        public ptLineModel: PtLineModelService,
        public ptStopModel: PtStopModelService,
        public aggAreaModel: AggregatedAreaModelService,
        private gtfsModel: GtfsModelService,
        public  emptyModel: EmptyModelService,
        public selectionPolygonController: SelectionPolygonControllerService
    ) {
        this.defaultFocusModel = this.nineyDefaultService.defaultFocusModel;
        this.featureModels = {
            "ptLine": this.ptLineModel,
            "ptStop": this.ptStopModel,
            "area": this.aggAreaModel,
            "empty": this.emptyModel
        };
        this.ptStopModel.gtfsModel = this.gtfsModel;
        this.gtfsModel.featureModel = this.ptStopModel;
    }
    
    
    ngDoCheck(): void  {
        if (this.scenario == null) {
          return;
        }
        if (this.toolModel.params == null) {
          return;
        }

        if (this.tool != this.toolModel.tool) {
            this.tool = this.toolModel.tool;
            this.setToolOptions();
            
            this.selectionPolygonController.selectableFeatureTypes = [];
            if (this.toolModel.tool.featureModels != null) {
                for (let key in this.toolModel.tool.featureModels) {
                    const toolFeatureModel = this.toolModel.tool.featureModels[key];
                    if (toolFeatureModel.selectable) {
                        this.selectionPolygonController.selectableFeatureTypes.push(this.featureModels[key]);
                    }
                }
            }
        }

        if (
            (this.wmsLayer == null) || (this.wmsLayer.name != this.toolModel.tool.name) ||
            (this.wmsLayer.vendorSpecifics.SCENARIONR != this.scenario.id) ||
            (!this.equalsToolOptions()) ||
            ((this.tool.type == "segment") && (this.wmsLayer.vendorSpecifics.SEL != this.selectionModel.getSegmentIds())) ||
            (((this.tool.type == "aggregated-area") || (this.tool.type == "zone")) && (this.wmsLayer.vendorSpecifics.SEL != this.selectionModel.getAreaIds()))
        ) {
          this.setWmsLayer();
        }
  }

  zoomIn() {
    var cs = this.defaultFocusModel.animationCenterScale;
    this.defaultFocusModel.setCenterScale(new CenterScale(cs.centerX, cs.centerY, cs.scale / 2), FocusModel.ALWAYS_NEAREST);
  }

  zoomOut() {
    var cs = this.defaultFocusModel.animationCenterScale;
    this.defaultFocusModel.setCenterScale(new CenterScale(cs.centerX, cs.centerY, cs.scale * 2), FocusModel.ALWAYS_NEAREST);
  }

  private setWmsLayer() { 
    if (this.toolModel.tool.name == null) return;
    var layer = new Layer(this.toolModel.tool.name);
    layer.baseURL = "http://app.movemeter.com/php/mapserver/maakkaart.php";
    layer.vendorSpecifics = {
      GEBIEDNR: this.scenario.id,
      SCENARIONR: this.scenario.id,
      VARIABEL: this.toolModel.tool.name,
      VIEWPARAMS: ""
    };
    if (this.tool.params != null) {
      for (let param of this.tool.params) {
        if ((param.name != null) && (param.option != null)) {
          layer.vendorSpecifics[param.name] = param.option.value;
        }
      }
    }
    if (this.tool.type == "segment") {
        layer.vendorSpecifics.SEL = this.selectionModel.getSegmentIds();
    } else if ((this.tool.type == "aggregated-area") || (this.tool.type == "zone")) {
        layer.vendorSpecifics.SEL = this.selectionModel.getAreaIds();
    }
    if (this.tool.predefinedParams) {
        for (let predefinedParam of this.tool.predefinedParams) {
          layer.vendorSpecifics[predefinedParam[0]] = predefinedParam[1];
        }
    }

    this.wmsLayer = layer;
  }
  
  private setToolOptions() {
    let toolOptions = [];
    let params = this.toolModel.tool.params;
    if (params != null) {
      for (let param of params) {
        toolOptions.push(param.option);
      }
    }
    this.toolOptions = toolOptions;
  }

  private equalsToolOptions() {
    let equals = true;
    let params = this.toolModel.tool.params;
    if (params != null) {
      for (let i = 0; i < params.length; i++) {
        if (this.toolOptions[i] != params[i].option) {
          this.toolOptions[i] = params[i].option;
          equals = false;
        }
      }
    }
    return equals;
  }
}
