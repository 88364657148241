import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { WindowModelService } from '../window-model.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, OnDestroy {
  private clickHandler = (function(mouseEvent: MouseEvent) {
    if ((mouseEvent.type == "mousedown") && this.isButton(mouseEvent.target)) {
        return;
    }
    if ((mouseEvent.type == "click") && !this.isButton(mouseEvent.target)) {
        return;
    }
    const clickedInside = this.hostRef.nativeElement.contains(mouseEvent.target);
    if (!clickedInside) {
        this.windowModel.resetMenu();
    }
  }).bind(this);
  @Output() close: EventEmitter<any> = new EventEmitter(true);

  constructor(private hostRef: ElementRef, private windowModel: WindowModelService) { }

  ngOnInit() {
    this.hostRef.nativeElement.style.zIndex = this.windowModel.z + 1;

    document.addEventListener("mousedown", this.clickHandler);
    document.addEventListener("click", this.clickHandler);
  }

  ngOnDestroy() {
    document.removeEventListener("mousedown", this.clickHandler);
    document.removeEventListener("click", this.clickHandler);
    
    this.close.emit(null);
  }

  private isButton(element: Element) {
    if (element == null) {
      return false;
    }
    if ((element["tagName"] == "BUTTON") || (element["tagName"] == "A")) {
      return true;
    }
    return this.isButton(element.parentElement);
  }
}
