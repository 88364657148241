import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { UserModelService } from './user-model.service'; 
import { environment }   from '../environments/environment'; 

@Injectable({
  providedIn: 'root'
})
export class SourceModelService {
  sourceOdMatrices: Object[];
  sourceNetwerken: Object[]; 
  sourceNetwerkReferentielijnen: Object[];
  predefinedReferenceLines: Object[];
  ptSkims: Object[];
  ptStops: Object[];
  ptHbs: Object[]; 

  constructor(public userModel: UserModelService, private http: HttpClient) { 
    this.init();
   }

  init() {  

    this.http.get(environment.serverUrl + "/rest/area/getSourceOdMatricesForUser/" + this.userModel.getUserId())
      .subscribe(
        (response: Object[]) => {
          this.sourceOdMatrices = response;
        }
    );

    this.http.get(environment.serverUrl + "/rest/area/getSourceSpeedsForUser/" + this.userModel.getUserId())
    .subscribe(
      (response: Object[]) => {
        this.sourceNetwerken = response;
      }
    );
 
    this.http.get(environment.serverUrl + "/rest/area/getUniqueSourceSpeedsReferencelinesForUser/" + this.userModel.getUserId())
    .subscribe(
      (response: Object[]) => {
        this.sourceNetwerkReferentielijnen = response;
      }
    );
 /*
 
    this.sourceNetwerkReferentielijnen = [
      {id: 1, name: 'Antwerpen 2030 (ochtend+avond)', referenceLine: '-0.10; -0.012; 1.203; 0.532; 8; 14; -0.085; -0.009; 1.071; 0.676; 8; 14; voorbeeld toelichting bij dit 1'},
      {id: 2, name: 'Antwerpen 2020 (ochtend+avond)', referenceLine: '-0.09; -0.011; 1.203; 0.532; 9; 16; -0.085; -0.009; 1.071; 0.676; 9; 15; voorbeeld toelichting bij dit 2'},
      {id: 3, name: 'Bethlehem v2 (ochtend+avond)',   referenceLine: '-0.08; -0.010; 1.203; 0.532; 8; 14; -0.085; -0.009; 1.071; 0.676; 8; 14; voorbeeld toelichting bij dit 3'},
      {id: 4, name: 'Tilburg 2040 v7 ochtend',   referenceLine: '-0.08; -0.010; 1.203; 0.532; 8; 14; -0.085; -0.009; 1.071; 0.676; 8; 14; voorbeeld toelichting bij dit 4'},
      {id: 5, name: 'Tilburg 2040 v7 avond',   referenceLine: '-0.075; -0.011; 1.203; 0.532; 9; 14; -0.085; -0.009; 1.071; 0.676; 8; 14; voorbeeld toelichting bij dit 5'}
    ];
 */

    this.http.get(environment.serverUrl + "/rest/area/getPredefinedReferenceLines")
    .subscribe(
      (response: Object[]) => {
        this.predefinedReferenceLines = response;
      }
    );
   
 /*
    this.predefinedReferenceLines = [
      {id: 1, name: 'HERE Foto 2016 (voor beter benutten)', referenceLine: '-0.10; -0.012; 1.203; 0.532; 8; 14; -0.085; -0.009; 1.071; 0.676; 8; 14; HERE Foto 2016 (voor beter benutten)'},
      {id: 2, name: 'NRM gemiddelde 2014', referenceLine: '-0.09; -0.011; 1.203; 0.532; 9; 16; -0.085; -0.009; 1.071; 0.676; 9; 15; NRM gemiddelde 2014'},
      {id: 3, name: 'Inrix februari 2017',   referenceLine: '-0.08; -0.010; 1.203; 0.532; 8; 14; -0.085; -0.009; 1.071; 0.676; 8; 14; Inrix februari 2017'}
    ];
*/

    this.http.get(environment.serverUrl + "/rest/area/getPtStopSkimsForUser/" + this.userModel.getUserId())
    .subscribe(
      (response: Object[]) => {
        this.ptStops = response;
      } 
    ); 
    

    this.ptSkims = null;
    this.ptHbs = null; 

  }

  loadPtMatricesByOdCar(odCar) {
    
    this.http.get(environment.serverUrl + "/rest/area/getPtSkimsForUser/" + this.userModel.getUserId() + "/" + odCar)
    .subscribe(
      (response: Object[]) => {
        this.ptSkims = response;
      }
    );

    this.http.get(environment.serverUrl + "/rest/area/getOdMatricesForModalityForUser/" + this.userModel.getUserId() + "/2/" + odCar)
    .subscribe(
      (response: Object[]) => {
        this.ptHbs = response;
      }
    );  
  }


  

}
