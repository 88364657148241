import { Injectable, NgZone } from "@angular/core"; 
import { ReferenceLineParams } from "./reference-line-params";  
import { HttpClient } from '@angular/common/http';
import { ToolModelService } from "./tool-model.service";
import { environment }   from '../environments/environment'; 

@Injectable({
    providedIn: "root"
})

export class ReferenceLineModelService { 
    valid: boolean; 
    error: string;
    hemelsbreed: ReferenceLineParams;
    netwerk:     ReferenceLineParams;
    comment: string;
    samplePoints: any[];
    indicatornummer: number;
    referenceLineString: string = "";

    constructor(private ngZone: NgZone, private http: HttpClient, private toolModel: ToolModelService) {
        this.ngZone.onMicrotaskEmpty.subscribe({
            next: () => {
                if (this.indicatornummer != this.toolModel.params["INDICATOR_ID"].option.value) {
                    this.onChange();
                }
            }
        });
        this.onChange();
    }

    onChange() {
            this.indicatornummer = this.toolModel.params["INDICATOR_ID"].option.value;
            this.setReferencelineFromIndicatornummer();
            this.getSamplePoints();
    }
    
    setReferencelineFromIndicatornummer() {
        let url = environment.serverUrl + "/rest/bbidata/getBbiReferenceline/" + this.indicatornummer;
        this.http.get(url, {responseType: 'text'}).subscribe(
            (response: string) => {
                this.initWithBbiString(response);
            }
        );
    }

    initWithBbiString(bbiString: string): void {
        /* example of valid bbiString:
           -0.11; -0.014; 1.203; 0.532; 8; 14; -0.095; -0.011; 1.071; 0.676; 8; 14; comment or explanation
        */
        if (this.referenceLineString == bbiString) return;
        this.referenceLineString = bbiString;
        if (!bbiString || bbiString == "") {
            this.returnInvalid("referentielijn niet gezet");
            return;
        }
        this.valid = true;
        this.samplePoints = [];
        let splitArray: any[];
        splitArray = bbiString.split(";");
        if (splitArray.length  < 12) this.returnInvalid("Te weinig parameters voor een geldige referentielijn");
        if (splitArray.length == 12) this.returnInvalid("Commentaar als laatste parameter mist, maar is verplicht");
        if (splitArray.length  > 13) this.returnInvalid("Te veel parameters voor een geldige referentielijn");
        let numArray: number[] = [];
        for (let i = 0; i < 12; i++) {
            let num: number;
            numArray.push(parseFloat(splitArray[i]));
            if (isNaN(numArray[i])) this.returnInvalid("Parameter #" + i + " is geen geldig getal");
        }
        this.hemelsbreed = new ReferenceLineParams(numArray[0], numArray[1], numArray[2], numArray[3], numArray[4], numArray[5]);
        this.netwerk =     new ReferenceLineParams(numArray[6], numArray[7], numArray[8], numArray[9], numArray[10], numArray[11]);
        this.comment = splitArray[12]; 
    }
 

    getSamplePoints(): void {
        let url = environment.serverUrl + "/rest/bbidata/getBbiSample/" + this.indicatornummer + "/200";
        this.http.get(url).subscribe(
            (response: any[]) => { 
                this.samplePoints = response;
            }
        );
    }

    isValid(): boolean {
        return this.valid;
    }

    asText(): string {
        if (!this.isValid()) {
            return this.error;
        } else {
            return this.comment;
        }
    }

    private returnInvalid(errorMessage): void { 
        if (this.valid) { // only give first error back
            this.valid = false;
            this.error = errorMessage;
        }
    }


}

