declare var Feature: any;
declare var FeatureModel: any;
declare var FeatureType: any;
declare var Point: any;
declare var Polygon: any;
declare var Property: any;
declare var PropertyType: any;

import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ScenarioModelService } from "./scenario-model.service";
import { ToolModelService } from "./tool-model.service";
import { environment }   from '../environments/environment'; 

@Injectable({
    providedIn: "root"
})
export class AggregatedAreaModelService extends (FeatureModel as { new(a: any, b: any): any; }) {
    private urlObject = null;
    private selectionGeometries = [[], [], [], []];
    
    constructor(
        private zone: NgZone,
        private http: HttpClient,
        private scenarioModel: ScenarioModelService,
        private toolModel: ToolModelService
    ) {
        super([], new FeatureType("aggregatedAreas", new Array(
            new Property("id", PropertyType.prototype.INTEGER),
            new Property("type", PropertyType.prototype.INTEGER),
            new Property("selected", PropertyType.prototype.INTEGER),
            new Property("geometry", PropertyType.prototype.GEOMETRY)
        )));
        
        this.zone.onMicrotaskEmpty.subscribe({
            next: () => {
                if (this.detectChanges()) {
                    this.load();
                }
            }
        });
    }
    
    setSelectionGeometry(geometry, cumulative) {
        const areaLevel = this.toolModel.params["AGGREGATIEGEBIEDEN"].option.value;
        if (geometry == null) {
            this.selectionGeometries[areaLevel] = null;
        } else if (!cumulative) {
            this.selectionGeometries[areaLevel] = [geometry];
        } else {
            this.selectionGeometries[areaLevel].push(geometry);
        }
        this.select();
    }
    
    private detectChanges() {
        if ((this.toolModel.tool.featureModels == null) || (this.toolModel.tool.featureModels["area"] == null)) {
            if (this.urlObject != null) {
                this.urlObject = null;
                return true;
            }
            return false;
        }
        if (
            (this.urlObject == null) ||
            (this.urlObject.name != this.toolModel.tool.featureModels["area"].name) ||
            (this.urlObject.level != this.toolModel.params["AGGREGATIEGEBIEDEN"].option.value) ||
            (this.urlObject.gridSize != this.toolModel.params["AGGREGATIEGEBIEDEN"].options[3].params[0].options[0].value) ||
            (this.urlObject.scenarioID != this.scenarioModel.scenario.id)
        ) {
            this.urlObject = {
                name: this.toolModel.tool.featureModels["area"].name,
                level: this.toolModel.params["AGGREGATIEGEBIEDEN"].option.value,
                gridSize: this.toolModel.params["AGGREGATIEGEBIEDEN"].options[3].params[0].options[0].value,
                scenarioID: this.scenarioModel.scenario.id
            };
            return true;
        }
        return false;
    }
    
    private load() {
        this.features = [];
        
        const url = this.getUrl();
        if (url == null) {
            return;
        }
        
        this.http.get(url).subscribe(
            response => {
                const features = [];
                for (let a of <any[]>response) { 
                    const point = new Point(a.coordX, a.coordY);
                    const id = a.areaId;
                    const type = 2;
                    let selected = 0;
/*                    if (
                        (this.selectionGeometry != null) &&
                        (this.selectionGeometry instanceof Polygon) &&
                        (point.intersects(this.selectionGeometry))
                    ) {
                        selected = 1;
                    }*/
                    features.push(new Feature(this.featureType, [id, type, selected, point]));
                }
                this.features = features;
                this.select();
            }
        );
    }
    
    private select() {
        const areaLevel = this.toolModel.params["AGGREGATIEGEBIEDEN"].option.value;
        const selectionGeometries = this.selectionGeometries[areaLevel];
        let features = [];
        if ((selectionGeometries == null) || !(selectionGeometries[0] instanceof Polygon)) {
            for (let feature of this.features) {
                feature.propertyValues[2] = 0;
                features.push(feature);
            }
            this.features = features;
        }
        if (selectionGeometries != null) {
            for (let selectionGeometry of selectionGeometries) {
                features = [];
                if (selectionGeometry instanceof Polygon) {
                    for (let feature of this.features) {
                        if (feature.propertyValues[3].intersects(selectionGeometry)) {
                            feature.propertyValues[2] = 1;
                        } else {
                            feature.propertyValues[2] = 0;
                        }
                        features.push(feature);
                    }
                } else if (selectionGeometry instanceof Point) {
                    let nearestDistance2 = Number.MAX_VALUE;
                    let nearestFeature = null;
                    for (let feature of this.features) {
                        const dx = selectionGeometry.x - feature.propertyValues[3].x;
                        const dy = selectionGeometry.y - feature.propertyValues[3].y;
                        const distance2 = (dx * dx + dy * dy);
                        if (nearestDistance2 > distance2) {
                            nearestDistance2 = distance2;
                            nearestFeature = feature;
                        }
                        features.push(feature);
                    }
                    if (nearestFeature != null) {
                        nearestFeature.propertyValues[2] = (nearestFeature.propertyValues[2] + 1) % 2;
                    }
                }
                this.features = features;
            }
        }
    }
    
    private getUrl():string {
        if (this.urlObject == null) {
            return null;
        }
        
        let url = environment.serverUrl + "/rest";
        for (let key in this.urlObject) {
          url += "/" + this.urlObject[key];
        }
        return url;
    }
}
