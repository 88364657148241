import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserModelService {
    user;
    /* = {
        id: 41,
        name: 'Tilburg'
    }*/
    
    loadUser(username, password) {
        // TODO1: validate on server
        // TODO2: start session on server and set cookie
        console.log(username + " " + password);
        if (username == "superuser") {
            this.user = {
                id: 1,
                name: 'Super'
            }
        } else if (username == "tilburg") {
            this.user = {
                id: 41,
                name: 'Tilburg'
            }
        } else if (username == "nono") {
            this.user = {
                id: 346897,
                name: 'Nono'
            }
        }
    }

  public getUserId() {
    if (this.user == null) return null;

    return this.user.id;
  }

  public getUserRights() {
    return 2;
  }

  public getUserName() { 
    if (this.user == null) return null;

    return this.user.name;
  }

  public getUserRightName() {
    return "expert";
  }

}
