import { Departure } from './departure';

export class TripProperties {
    
    constructor(
        public departures: Departure[],
        public stopName: string,
        public routeShortName?: string,
        public routeLongName?: string,
        public tripHeadsign?: string,
        public departureTime?: string
    ) { }
 
}

