import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: "type",
    pure: false
})
export class TypePipe implements PipeTransform {
    
    transform(items: any[], type: string): any {
        return items.filter(v => v.type == type);
    }
}
