declare var Point: any;

import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NineyDefaultService } from "./niney-default.service";
import { ScenarioModelService } from "./scenario-model.service";
import { ToolModelService } from "./tool-model.service";
import { GtfsModelService } from './tool-panel/custom/gtfs/gtfs-model.service';
import { GenericMeasureComponent } from './tool-panel/custom/generic-measure/generic-measure.component';

@Injectable({
    providedIn: "root"
})
export class SelectionModelService {
    private urlObject = null;
    segments = [];
    areas = [[], [], [], []];  // aggregated zones, districts, municipalities, unaggregated zones
    message;
     
    //    private genericMeasureComponent: GenericMeasureComponent
    constructor(
        private zone: NgZone,
        private http: HttpClient,
        private nineyDefaultService: NineyDefaultService,
        private scenarioModel: ScenarioModelService,
        private toolModel: ToolModelService,
        private gtfsModel: GtfsModelService
    ) {
        this.zone.onMicrotaskEmpty.subscribe({
            next: () => {
                if (this.detectChanges()) {
                    //this.reset();
                }
            }
        });
    }
    
    setSelectionGeometry(geometry, cumulative) {
        this.load(geometry, cumulative);
    }
    
    getSegmentIds() {
        const segmentIds = [];
        for (let segment of this.segments) {
            segmentIds.push(segment.id);
        }
        return segmentIds.join("|");
    }
    
    getAreas(): String[] {
        if (this.toolModel.params == null) {
            return [];
        }
        
        let areaLevel = this.toolModel.params["LEVEL"].option.value;
        if (this.toolModel.tool.type == "zone") {
            areaLevel = 3;
        }
        return this.areas[areaLevel];
    }
    
    getAreaIds() {
        return this.getAreas().join("|");
    }
    
    private detectChanges() {
        if ((this.toolModel.tool.type == "segment") || (this.toolModel.tool.type == "aggregated-area") || (this.toolModel.tool.type == "zone")) {
            if (
                (this.urlObject == null) ||
                (this.urlObject.SCENARIONR != this.scenarioModel.scenario.id) ||
                (this.urlObject.AKTKAART != this.toolModel.tool.name)
            ) {
                this.urlObject = {
                    SCENARIONR: this.scenarioModel.scenario.id,
                    AKTKAART: this.toolModel.tool.name
                };
                return true;
            }
        } else if (this.toolModel.tool.type == "gtfs") {
            if (this.urlObject != null) {
                this.urlObject = null;
                return true;
            }
        }
        return false;
    }
    
    private reset() {
        this.segments = [];
        this.areas = [[], [], [], []];
        this.message = null;
        this.gtfsModel.setSelectedPtStop(null);
    }
    
    private load(geometry, cumulative) {
        const toolType = this.toolModel.tool.type;
        if (toolType == null) {
            return;
        }
        
        if (toolType == "segment") {
            this.loadSegments(geometry, cumulative);
        } else if ((toolType == "area") || (toolType == "aggregated-area") || (toolType == "zone")) {
            this.loadAreas(geometry, cumulative);
        } else if (toolType == "gtfs") {
            this.gtfsModel.setPtStop(geometry);
        } else if (toolType == "genericMeasure") {
       //     this.genericMeasureComponent.geometryEvent(geometry, cumulative);
        }
    }
    
    private loadSegments(point, cumulative) {
        if ((point == null) || !(point instanceof Point)) {
            this.setSegment(null, cumulative);
            return;
        }
        
        const scale = this.nineyDefaultService.defaultFocusModel.centerScale.getNumWorldCoords(1);
        const url = "http://app.movemeter.com/php/mapserver/getInfo.php?format=json&Gebiednr=" + this.urlObject.SCENARIONR + "&Scenarionr=" + this.urlObject.SCENARIONR + "&hbanalyse_id=0&x=" + point.x + "&y=" + point.y + "&zoom=10&scale=" + scale + "&extent=562716.94177178,6918380.2619977,563620.45133256,6918739.7548963&datatype=pol&aktKaart=" + this.urlObject.AKTKAART + "&rechten=2&step=2&toeval=0.967017400665183";
        this.http.get(url).subscribe(
            response => {
                if (response["segments"].length == 0) {
                    this.setSegment(null, cumulative);
                } else {
                    const segment = { id: response["segments"][0].id.split("-")[1], directions: response["segments"] };
                    this.setSegment(segment, cumulative);
                }
                this.message = response["message"];
            }
        );
    }
    
    private setSegment(segment, cumulative) {
        if (segment == null) {
            if (!cumulative) {
                this.segments = [];
            }
        } else {
            if (!cumulative) {
                this.segments = [segment];
            } else {
                this.toggleSegment(segment);
            }
        }
    }
    
    private toggleSegment(segment) {
        for (let i = 0; i < this.segments.length; i++) {
            if (this.segments[i].id == segment.id) {
                this.segments.splice(i, 1);
                return;
            }
        }
        this.segments.push(segment);
    }
    
    private loadAreas(point, cumulative) {
        if (this.toolModel.params == null) {
            return;
        }
        
        let areaLevel = this.toolModel.params["LEVEL"].option.value;
        //const areaLevel = this.toolModel.params["AGGREGATIEGEBIEDEN"].option.value;
        if (this.toolModel.tool.type == "zone") {
            areaLevel = 3;
        }
        if ((point == null) || !(point instanceof Point)) {
            this.setArea(areaLevel, null, cumulative);
            return;
        }
        
        const scale = this.nineyDefaultService.defaultFocusModel.centerScale.getNumWorldCoords(1);
        const url = "http://app.movemeter.com/php/mapserver/GetWegvakken.php?WKT=POINT(" + point.x + " " + point.y + ")&Gebiednr=" + this.urlObject.SCENARIONR + "&Scenarionr=" + this.urlObject.SCENARIONR + "&hbanalyse_id=0&scale=" + scale + "&extent=543045.43442696,6818023.403194,600870.04631664,6832049.5978824&bereikbaarheidsindicator=" + (areaLevel < 3 ? 7291 : -1) + "&bereikbaarheidsindicatorLevel=" + (areaLevel < 3 ? areaLevel : -1) + "&polygonmodus=" + (areaLevel < 3 ? "bereikbaarheidsindicator" : "bron_isochroon_van_naar") + "&dartbordindex=&buitenNederland=0&ctrlKey=false&sel=&aggregatiegebieden=";
        this.http.get(url).subscribe(
            response => {
                const areaId = response["ids"][0];
                this.setArea(areaLevel, areaId, cumulative);
                this.message = response["message"];
            }
        );
    }
    
    private setArea(areaLevel, areaId, cumulative) {
        if (areaId == null) {
            if (!cumulative) {
                this.areas[areaLevel] = [];
            }
        } else {
            if (!cumulative) {
                this.areas[areaLevel] = [areaId];
            } else {
                this.toggleArea(areaLevel, areaId);
            }
        }
    }
    
    private toggleArea(areaLevel, areaId) {
        for (let i = 0; i < this.areas[areaLevel].length; i++) {
            if (this.areas[areaLevel][i] == areaId) {
                this.areas[areaLevel].splice(i, 1);
                return;
            }
        }
        this.areas[areaLevel].push(areaId);
    }
}
