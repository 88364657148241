import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GtfsEditModelService } from './gtfs-edit-model.service';
import { Departure } from './domain/departure';
import { Stop }   from './domain/stop'; 
import { Trip }   from './domain/trip'; 
import { TripProperties }   from './domain/trip-properties'; 
import { environment }   from '../../../../environments/environment'; 

declare var Feature: any;
declare var Point: any; 

@Injectable({
  providedIn: 'root'
})
export class GtfsModelService {

  constructor(private http: HttpClient, public gtfsEditModel: GtfsEditModelService) { }

    public featureModel;
  
  public selectedPtStop: Stop = null;
  public selectedPtStopBack: Stop = null;
  public selectedTripProperties: TripProperties = null; 
  public selectedTripId = null;
  public selectedTrip: Trip = null;

  public setSelectedTripProperties(): void {
    if (!this.selectedTripProperties.routeShortName) {
      if (this.selectedTrip && this.selectedTrip.routeShortName) {
        this.selectedTripProperties.routeShortName = this.selectedTrip.routeShortName;
        this.selectedTripProperties.tripHeadsign = this.selectedTrip.tripHeadsign;
      }
    }
  }

  drawStops(trip?: Trip) {
    if (!trip) trip = Trip.emptyTrip();    // no feature is selected
    if (!this.gtfsEditModel.ptStops) return;             // stops not yet loaded
    let features = []; 
    for (let stop of this.gtfsEditModel.ptStops.getPtStops()) {   
      features.push(new Feature(this.featureModel.featureType, [stop.id, 2, trip.containsStop(stop.id), new Point(stop.x, stop.y)]));
    }
    this.featureModel.features = features;
  }
 
  setSelectedPtStop(selectedPtStop) {
    this.selectedPtStop = selectedPtStop; 
    this.loadDepartures();
  }

  private loadDepartures() { 
      if (this.selectedPtStop == null) return;
      if (this.selectedPtStop.departures != null) return;   // already loaded departures for this stop 
      const url:string = environment.serverUrl + "/rest/gtfs/getDeparturesForStop/" + this.selectedPtStop.id;
      this.drawStops();
      this.selectedTripId = null;
       this.http.get(url).subscribe(
        response => {  
            let departures: Departure[] = [];
            for (let departure of <any>response) {
              departures.push(Departure.parseDeparture(departure));
            }
            this.selectedPtStop.departures = departures;  
        }
      ); 
  }

  selectTrip(tripId:number) {  
    this.selectedTripId = tripId; 
    this.selectedPtStopBack = this.selectedPtStop;
    this.getTripsOnlyThisLine(tripId);
    this.selectedPtStop = null;
    if (tripId < 0) {  // edited trip
      this.selectedTrip = this.gtfsEditModel.getExtraTripForId(tripId); 
      this.drawStops(this.selectedTrip);
    } else {           // base trip in database
      let url:string = environment.serverUrl + "/rest/gtfs/getTripStops/" + tripId; 
      this.selectedTrip = null;
      this.http.get(url).subscribe(
        response => {   
          this.selectedTrip = Trip.parseTrip(response);
          for (let stop of this.selectedTrip.stopsOnTrip) {
          stop.selectedForEdit = false;
          }
          this.drawStops(this.selectedTrip);
        }
      );  
    }
  }

  getTripsOnlyThisLine(tripId) { 
    this.selectedTripProperties = new TripProperties([], this.selectedPtStop.name); 
    for (let departure of this.selectedPtStop.departures) {
      if (departure.tripId == tripId) {
        this.selectedTripProperties.routeShortName = departure.routeShortName;
        this.selectedTripProperties.routeLongName = departure.routeLongName;
        this.selectedTripProperties.tripHeadsign = departure.tripHeadsign;
        this.selectedTripProperties.departureTime = departure.departureTime;
        break;
      }
    }
    for (let departure of this.selectedPtStop.departures) {
      if (departure.routeShortName == this.selectedTripProperties.routeShortName) {
        this.selectedTripProperties.departures.push(departure);
      }
    } 
  }

  public backToAllDeparturesThisStop() {
    this.selectedTripId = null;
    this.selectedPtStop = this.selectedPtStopBack;
    this.drawStops();
  }
  
  public setPtStop(point) {                                           // onClick event
    let stop = this.gtfsEditModel.ptStops.getClosest(point);
    if (this.gtfsEditModel.isEditing) {
      this.gtfsEditModel.ptStopClick(stop, point); 
    } else {
      this.setSelectedPtStop(stop);
    }
  }

  public setPtStops(response) { 
    let stops: Stop[] = [];
    for (let stop of response) {
      stops.push(Stop.parseStop(stop));
    } 
    this.gtfsEditModel.ptStops.setBaseStops(stops); 
    this.drawStops();
  }

}
