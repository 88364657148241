import { Component, ElementRef, ViewChild } from '@angular/core'; 
import {HttpClient, HttpParams, HttpRequest, HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {Observable} from "rxjs";
import { environment }   from '../../environments/environment'; 

@Component({
  selector: 'app-import',
  templateUrl: './app-import.component.html',
  styleUrls: ['./app-import.component.scss']
})

export class AppImportComponent {
  
  loading: boolean = false;
  message: string = "";
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private http: HttpClient) { }

  fileChange(event) {
    let files =  event.target.files;
    if (files.length == 0) {
      this.setMessage("Geen bestand geselecteerd");
      return
    }
    let file: File = files[0];
    this.loading = true;
    this.uploadFile(environment.serverUrl + "/uploadsourcedata", file)
      .subscribe(
        event => {
          if (event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round(10000 * event.loaded / event.total) / 100;
            this.setMessage("Bezig met upload, " + percentDone + " % klaar.");
          } else if (event instanceof HttpResponse) {
            this.setMessage('Bestand is volledig geupload en wordt nu op de server verwerkt.');
          }
        },
        (err) => {
          // console.log("Upload Error:", err);
        }, () => {
          // console.log("Upload done");
        }
      )
  }

  uploadFile(url: string, file: File): Observable<HttpEvent<any>> {

    let formData = new FormData();
    formData.append('upload', file);

    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', url, formData, options);
    return this.http.request(req);
  }

  setMessage(message: string):void {
      this.message = message;
  }
}
  