import { Component, Input } from "@angular/core";

@Component({
    selector: "app-custom",
    templateUrl: "./custom.component.html",
    styleUrls: ["./custom.component.scss"]
})
export class CustomComponent {
    @Input() tool;
    @Input() param;
}
