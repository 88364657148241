import { Component, OnInit, DoCheck } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { LanguageModelService } from "../../../language-model.service";
import { ToolModelService } from "../../../tool-model.service";
import { ScenarioModelService } from "../../../scenario-model.service";
import { CustomComponent } from "../custom.component";
import { GtfsModelService } from './gtfs-model.service';
import { GtfsEditModelService } from './gtfs-edit-model.service';
import { TimeFunctions } from './domain/time-functions';
import { StopOnTrip }    from './domain/stop-on-trip';

@Component({
  selector: 'app-gtfs',
  templateUrl: './gtfs.component.html',
  styleUrls: ['./gtfs.component.scss']
})
export class GtfsComponent  extends CustomComponent implements OnInit, DoCheck {

  public ACTION_NO = 0;
  public ACTION_DUBLICATE_TRIP = 1;
  public ACTION_REMOVE_TRIP = 2;
  public ACTION_SPEED = 3;
  public ACTION_REMOVE_STOP_FROM_TRIP = 4;
  public ACTION_ADD_STOP_TO_TRIP = 5;
  public ACTION_ADD_LINE = 6;
  public action = this.ACTION_NO; 

  public changeStopTime = false;
  public changeTravelTime = false;
  public timeActions = [{id: 1, label: "verlaag met aantal seconden"}, 
                        {id: 2, label: "verhoog met aantal seconden"}, 
                        {id: 3, label: "verlaag met percentage"}, 
                        {id: 4, label: "verhoog met percentage"}]; 
  public timeActionIdChangeStopTime = 1;
  public timeActionIdChangeTravelTime = 1;
  public valueChangeStopTime = "";
  public valueChangeTravelTime = "";
  public valueNameNewStop = "";
  public MAX_REPETITIONS = 20;
  public numberRepetitions = 1;
  public repetitionValues:number[] = this.generateArray(this.MAX_REPETITIONS);
  public savedTravelTimeForEachRemovedStop = 30;
  public actionForWhichSortOfTrips: number = 1;
  public selectedStopRadioIndex = -1;
  public selectedStopRadio = null;
  public measureScenario = null;
  public addStopMode = 1;
  public addLijnDeparture = "";
  public addLijnNummer = ""; 
  public addLijnHeadSign = "";  
  

  constructor(private http: HttpClient, public l:LanguageModelService, private scenarioModel:ScenarioModelService, 
              private toolModel:ToolModelService, public gtfsModel: GtfsModelService, public gtfsEditModel: GtfsEditModelService  ) { 
    super();
  }

  ngOnInit() {
  }

  ngDoCheck() {
    if (this.measureScenario != this.scenarioModel.measureScenario) {
      this.measureScenario = this.scenarioModel.measureScenario;
      this.gtfsModel.selectedTrip = null;
      this.gtfsEditModel.getChangesForScenario(this.measureScenario);
    }
    if (this.gtfsEditModel.needsRedraw) {
      this.gtfsEditModel.needsRedraw = false;
      this.gtfsModel.drawStops();
    }
  }

  public setAction(action) {
    this.action = action;
    this.gtfsEditModel.isEditing = (action != this.ACTION_NO);
    if (!this.gtfsEditModel.isEditing) {
        this.gtfsEditModel.ptStopClicked = null;
    }
  }

  public generateArray(numberRepetitons) {
    let arr = [];
    for (let i = 1; i <= numberRepetitons; i++) {
      arr.push(i);
    }
    return arr;
  }

  public setRepetition(indexBase1, value) {
      this.repetitionValues[indexBase1 - 1] = value;
  }

  public executeRepeatTrips() {
    this.gtfsModel.setSelectedTripProperties();
    this.gtfsEditModel.repeatTripGroup(this.gtfsModel, this.actionForWhichSortOfTrips, this.repetitionValues.slice(0, this.numberRepetitions)); 
    this.setAction(this.ACTION_NO); 
    this.gtfsModel.backToAllDeparturesThisStop(); 
  }

  public executeDeleteTrips() {
    this.gtfsEditModel.deleteTripGroup(this.gtfsModel, this.actionForWhichSortOfTrips); 
    this.setAction(this.ACTION_NO); 
    this.gtfsModel.backToAllDeparturesThisStop();
  }

  public executeAddLine() {
    this.gtfsEditModel.executeAddLine(this.gtfsModel.selectedPtStopBack.id, this.addLijnDeparture, this.addLijnNummer, this.addLijnHeadSign); 
    this.setAction(this.ACTION_NO); 
    this.gtfsModel.backToAllDeparturesThisStop();
  }

  public executeChangeTravelTime() {
    this.gtfsModel.setSelectedTripProperties();
    this.gtfsEditModel.changeTravelTimeTripGroup(
      this.gtfsModel,
      this.actionForWhichSortOfTrips,
      this.changeStopTime, 
      this.timeActionIdChangeStopTime, 
      this.valueChangeStopTime, 
      this.changeTravelTime, 
      this.timeActionIdChangeTravelTime, 
      this.valueChangeTravelTime, 
      this.gtfsModel.selectedTrip);
    this.setAction(this.ACTION_NO); 
    this.gtfsModel.backToAllDeparturesThisStop();       
  }

  executeRemoveStopFromTrip() {
    this.gtfsEditModel.removeStopFromTripGroup(
      this.gtfsModel,
      this.actionForWhichSortOfTrips,
      this.savedTravelTimeForEachRemovedStop,
      this.gtfsModel.selectedTrip);      
    this.setAction(this.ACTION_NO); 
    this.gtfsModel.backToAllDeparturesThisStop();       
  } 

  executeAddStopToTrip(readyEditing: boolean) {
    this.gtfsModel.setSelectedTripProperties();
    this.gtfsEditModel.addStopToTrip(
      this.gtfsModel,
      this.gtfsModel.selectedTripId,
      this.addStopMode, 
      this.selectedStopRadioIndex, 
      this.gtfsModel.selectedTripProperties,
      this.valueChangeTravelTime,
      this.valueChangeStopTime,
      this.valueNameNewStop,
      this.gtfsModel.selectedTrip);
    if (readyEditing) {
      this.setAction(this.ACTION_NO); 
      this.gtfsModel.backToAllDeparturesThisStop();         
    }
  }

  selectStopRadio(stopClicked) { 
    this.selectedStopRadio = stopClicked;
    this.selectedStopRadioIndex = this.selectedStopRadioGetIndexByStopId(stopClicked.stopId);
  }

  selectedStopRadioGetIndexByStopId(stopId: number): number {  
    for (let i = 0; i < this.gtfsModel.selectedTrip.getNumberStops(); i++) {
      let stop: StopOnTrip = this.gtfsModel.selectedTrip.stopsOnTrip[i];
      if (stop.stopId == stopId) {
        return i; 
      }
    } 
    return -1;
  }

  selectTrip(tripId: number) {
      this.selectedStopRadio = null;
      this.selectedStopRadioIndex = - 1;  
      this.gtfsModel.selectTrip(tripId);
  }

  getNewStopDistance() {
    let selectedStop = this.selectedStopRadio;
    if (!selectedStop) {
      selectedStop = this.gtfsModel.selectedTrip.stopsOnTrip[this.gtfsModel.selectedTrip.stopsOnTrip.length - 1];
    }
    let stop = this.gtfsEditModel.ptStops.getById(selectedStop.stopId); 
    let dx = stop.x - this.gtfsEditModel.ptStopClicked.x;
    let dy = stop.y - this.gtfsEditModel.ptStopClicked.y;
    let distance = Math.sqrt(dx*dx + dy*dy) / 1.62;
    return distance;
  }

  getNewStopDistanceText() {
      let distance = this.getNewStopDistance();
      if (distance <   100) return Math.round(distance) + "m";
      if (distance <   500) return Math.round(distance / 50) * 50 + "m";
      if (distance <  1000) return Math.round(distance / 100) * 100 + "m";
      if (distance <  5000) return Math.round(distance / 100) / 10 + "km";
      if (distance < 50000) return Math.round(distance / 1000) + "km";
      return Math.round(distance / 10000) * 10 + "km";
  }

  getNewStopTime(speedKmh) {
    let km = this.getNewStopDistance() / 1000; 
    let uren = km / speedKmh;
    let seconden = uren * 60 * 60;
    return Math.round(seconden);
  }

  getStopName(index: number): string {
   // return this.gtfsModel.selectedTrip.stopsOnTrip[index].arrivalTime + " " + this.gtfsModel.selectedTrip.stopsOnTrip[index].stopId;
    return this.gtfsEditModel.ptStops.getStopNameForStopOnTrip(this.gtfsModel.selectedTrip.stopsOnTrip[index])
  }

  formatTimeHMM(time: string): string {
    // workaround because in databinding static method seems impossible
    return TimeFunctions.formatTimeHMM(time);
  }
}
