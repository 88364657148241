declare var FeatureModel: any;
declare var FeatureType: any;
declare var Point: any;
declare var Property: any;
declare var PropertyType: any;

import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http"; 
import { ToolModelService } from "./tool-model.service";  
import { environment } from "../environments/environment";
 

@Injectable({
    providedIn: "root"
})
export class PtStopModelService extends (FeatureModel as { new(a: any, b: any): any; }) {
    private urlObject = null;
    private selectionGeometry = null;
    
    public gtfsModel;
    
    constructor(
        private zone: NgZone,
        private http: HttpClient, 
        private toolModel: ToolModelService 
    ) {
        super([], new FeatureType("ptStops", new Array(
            new Property("id", PropertyType.prototype.INTEGER),
            new Property("type", PropertyType.prototype.INTEGER),
            new Property("selected", PropertyType.prototype.INTEGER),
            new Property("geometry", PropertyType.prototype.GEOMETRY)
        )));
        
        this.zone.onMicrotaskEmpty.subscribe({
            next: () => {
                if (this.detectChanges()) {
                    this.load();
                }
            }
        });
    }
    
    setSelectionGeometry(geometry, cumulative) {
        this.selectionGeometry = geometry;
        this.select();
    }
    
    private detectChanges() {
        if ((this.toolModel.tool.featureModels == null) || (this.toolModel.tool.featureModels["ptStop"] == null)) {
            if (this.urlObject != null) {
                this.urlObject = null;
                return true;
            }
            return false;
        }
        if (
            (this.urlObject == null) ||
            (this.urlObject.name != this.toolModel.tool.featureModels["ptStop"].name)
        ) {
            this.urlObject = {
                name: this.toolModel.tool.featureModels["ptStop"].name
            };
            return true;
        }
        return false;
    }
    
    private load() {
        this.features = [];
        
        const url = this.getUrl();
        if (url == null) {
            return;
        }
        
        this.http.get(url).subscribe(
            response => {
                this.gtfsModel.setPtStops(response);
                this.select();
            } 
        );
    }
    
    private select() {
        if ((this.selectionGeometry != null) && (this.selectionGeometry instanceof Point)) {
            let features = [];
            let nearestDistance2 = Number.MAX_VALUE;
            let nearestFeature = null;
            for (let feature of this.features) {
                const dx = this.selectionGeometry.x - feature.propertyValues[3].x;
                const dy = this.selectionGeometry.y - feature.propertyValues[3].y;
                const distance2 = (dx * dx + dy * dy);
                if (nearestDistance2 > distance2) {
                    nearestDistance2 = distance2;
                    nearestFeature = feature;
                }
                features.push(feature);
            }
            if (nearestFeature != null) {
                nearestFeature.propertyValues[2] = 1;
            }
            this.features = features;
            //this.gtfsModel.setPtStop(this.selectionGeometry);
        }
    }
    
      

    private getUrl(): string {
        if (this.urlObject == null) {
            return null;
        }
        
        let url =environment.serverUrl + "/rest";
        for (let key in this.urlObject) {
          url += "/" + this.urlObject[key];
        }
        return url;
    }
}
