declare var Bounds: any;

import { Component, DoCheck, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ChartModel } from "../chart-model";
import { ScenarioModelService } from "../../scenario-model.service";
import { environment }   from '../../../environments/environment'; 
 

@Component({
    selector: "app-consistency-chart",
    templateUrl: "./consistency-chart.component.html",
    styleUrls: ["./consistency-chart.component.scss"]
})
export class ConsistencyChartComponent implements OnInit, DoCheck {
    @ViewChild("svg") private svgRef: ElementRef;
    
    private scenario = this.scenarioModel.scenario;
    
    chartModel: ChartModel = new ChartModel();
    bucketWidth = 10;
    restLoadDeviation = 200;
    loading = true;
    bars = null;
    
    constructor(private http: HttpClient, private scenarioModel: ScenarioModelService) { }
    
    ngOnInit() {
        const bounds = new Bounds(this.svgRef.nativeElement.offsetWidth, this.svgRef.nativeElement.offsetHeight);
        this.chartModel.setBounds(bounds);
        this.chartModel.xGrid.label = "afwijking in intensteit t.o.v. het bronmodel";
        this.chartModel.xGrid.unit = "%";
        this.chartModel.yGrid.label = "aandeel in de totale voertuigkilometers";
        this.chartModel.yGrid.unit = "%";
        
        this.loadBars();
    }
    
    ngDoCheck() {
        if (this.scenario != this.scenarioModel.scenario) {
            this.scenario = this.scenarioModel.scenario;
            this.loadBars();
        }
    }
    
    applyRules() {
        this.bucketWidth = Math.max(1, Math.round(this.bucketWidth));
        this.restLoadDeviation = Math.max(100, this.restLoadDeviation);
        this.bucketWidth = Math.min(this.bucketWidth, this.restLoadDeviation);
        this.restLoadDeviation = Math.round(this.restLoadDeviation / this.bucketWidth) * this.bucketWidth;
        
        this.loadBars();
    }
    
    private loadBars() {
        this.loading = true;
        const url = environment.serverUrl + "/rest/source-model-consistency/load-deviation-buckets/" + this.scenario.id + "/" + this.bucketWidth + "/" + this.restLoadDeviation;
        this.http.get(url)
            .subscribe(
            response => {
                this.loading = false;
                this.bars = [];
                
                if ((<any[]>response).length == 0) {
                    return;
                }
                
                const minX = response[0].minLoadDeviation;
                const maxX = response[(<any[]>response).length - 1].minLoadDeviation;
                let maxY = 0;
                for (let a of <any[]>response) {
                    if (maxY < a.relVehicleDistance) maxY = a.relVehicleDistance;
                }
                this.chartModel.setGridValues(minX, maxX, 0, Math.ceil(maxY));
                
                for (let a of <any[]>response) {
                    this.bars.push({
                        minX: this.chartModel.transGridX(a.minLoadDeviation),
                        maxX: this.chartModel.transGridX(a.minLoadDeviation + this.bucketWidth),
                        minY: this.chartModel.transGridY(a.relVehicleDistance),
                        maxY: this.chartModel.transGridY(0)
                    });
                }
            }
        );
    }
}
