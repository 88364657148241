import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { WindowModelService } from './window-model.service';
import { ScenarioModelService } from "./scenario-model.service";

@Injectable({
    providedIn: "root"
})
export class ToolModelService {
    categories;
    category;
    tool;
    params;
    mode = 1;  // analysis: 1, measure: 2, both: 3
    
    constructor(private http: HttpClient, private zone: NgZone, public windowModel: WindowModelService,
                 private scenarioModel: ScenarioModelService, public toolModel: ToolModelService)
    {
        let defaultTool = {
            title: "~welcome~",
            generalText: "~welcome-general-text~",
        };
        this.tool = defaultTool;
        
        this.load(); 
        this.scenarioModel.subscribeOnScenarioChanged(this);
    }
      
    scenarioChanged() {

                // TODO Dirk Bussche
                // ik heb geprobeerd dit via een ngDoCheck of Dependency Injection voor elkaar te krijgen, of anders een broadcast-service,
                // maar stom genoeg krijg ik dat nu niet aan te praat, dus tijdelijk even zo

        if (!this.scenarioModel) return;
        if (!this.getToolParam("INDICATOR_ID")) return; 
        let options: any[] = [];
        let oldName = this.getToolParam("INDICATOR_ID").option.name;
        if (this.scenarioModel.scenario.accessibilityIndicatorVariants) {
            for (let accessibilityIndicatorVariant of this.scenarioModel.scenario.accessibilityIndicatorVariants) {
                console.log(options.length);
            options.push({name: "~" + accessibilityIndicatorVariant.modality + "~", value: accessibilityIndicatorVariant.indicatorId});
            if (oldName == "~" + accessibilityIndicatorVariant.modality + "~") {
                this.getToolParam("INDICATOR_ID").option = options[options.length - 1];      
            }
            }
            this.params["INDICATOR_ID"].options = options; 
        }  
    }

    setTool(category, tool) {
        this.category = category;
        this.tool = tool;
        this.exit();
        this.init(tool);
    }
    
    exit() {

    }

    getToolParam(name: string): any {
        if (!this.tool || !this.tool.params) return null;
        for (let param of this.tool.params) {
            if (param.name == name) return param;
        }
        return null;
    }

    // set Tool param if exists, but do not set if there was no param with same name set earlyer. 
    // Because than it was a tool which does not need this param
    /*
    overwriteToolParam(name: string, options: any[]): void { 
        if (!this.tool || !this.tool.params) return;
        for (let param of this.tool.params) {
            if (param.name == name) {
                param.options = options;
              //  param.option = -1; // userPreferences.getModalityOption(options);
            };
        } 
    }    
*/
 
    init(tool) {
        switch(tool.name) {
            case "bereikbaarheidsindicator": { 
             //   let referentielijn: ReferenceLineModelService = new ReferenceLineModelService(this.http);
             //   referentielijn.initWithIndicatorId(0);
             //   TODO Dirk
             //   this.windowModel.referentielijn = referentielijn; 
            }
        }
    }

    getDayQuarterTimeObjects() {
        let quarterHours = ["00", "15", "30", "45"];
        let results = [];
        let counter = 0;
        for (let i = 0; i < 24; i++) {
            for (let j = 0; j < 4; j++) {
                let time = i + ":" + quarterHours[j];
                if (i < 10) {
                    time = "0" + time;
                }
                let timeObj = { "name": time, "value": "'" + counter + "'" };
                results.push(timeObj);
                ++counter;
            }
        }
        return results;
    }
    
    private load() {
        this.http.get("assets/tools.json").subscribe(
            response => {
                this.categories = <any>response["categories"];
                this.params = <any>response["params"];
                
                for (let category of this.categories) {
                    for (let tool of category.tools) {
                        if (tool.params != null) {
                            for (let i in tool.params) {
                                if (typeof tool.params[i] == "string") {
                                    tool.params[i] = this.params[tool.params[i]];
                                } else {
                                    this.initParam(tool.params[i]);
                                }
                            }
                        }
                    }
                }
                
                for (let key in this.params) {
                    this.initParam(this.params[key]);
                }
            }
        );
    }
    
    private initParam(param) {
        if (param.customFunction != null) {
            param.options = this[param.customFunction]();
        }
        param.option = param.options[0];
        
        for (let option of param.options) {
            if (option.params != null) {
                for (let i in option.params) {  // Subparams.
                    if (typeof option.params[i] == "string") {
                        option.params[i] = this.params[option.params[i]];
                    } else {
                        this.initParam(option.params[i]);
                    }
                }
            }
        }
    }
}
