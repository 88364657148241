import { Component, forwardRef } from '@angular/core';
import { CanvasSymbolizer } from '../map-feature-layer/canvas-symbolizer';
import { Symbolizer } from '../map-feature-layer/symbolizer';

@Component({
  selector: 'canvassymbolizer',
  templateUrl: './canvas-symbolizer.component.html',
  styleUrls: ['./canvas-symbolizer.component.scss'],
  providers: [{provide: Symbolizer, useExisting: forwardRef(() => CanvasSymbolizerComponent)}]
})
export class CanvasSymbolizerComponent extends CanvasSymbolizer {
}
