import { StopOnTrip }   from './stop-on-trip'; 

export class Trip {
    
    constructor(
        public id: number,
        public routeShortName: string,
        public tripHeadsign: string,
        public stopsOnTrip: StopOnTrip[]
    ) { }

    public static parseTrip(response: any): Trip { 
        let stopsOnTrip: StopOnTrip[] = [];
        let routeShortName: string = "";
        let tripHeadsign: string = "";
        for (let responseRecord of response) {
            stopsOnTrip.push(new StopOnTrip(responseRecord.stopId, responseRecord.departureTime, responseRecord.arrivalTime, responseRecord.stopName));
            routeShortName = responseRecord.routeShortName;
            tripHeadsign = responseRecord.tripHeadsign;
        }
        return new Trip(0, routeShortName, tripHeadsign, stopsOnTrip); 
    }
 

    public static parseTrips(extraTripsFlat: any): Trip[] { 
        let extraTrips: Trip[] = []; 
        let stopsOnTrip: StopOnTrip[] = []; 
        let nextTrip, trip;
        for (let i = 0; i < extraTripsFlat.length; i++) {
            trip = extraTripsFlat[i];
            if (i < extraTripsFlat.length - 1) {
                nextTrip = extraTripsFlat[i + 1]; 
            } else {
                nextTrip = {tripId: null}
            } 
            stopsOnTrip.push(new StopOnTrip(
                trip.stopId,
                trip.departureTime,
                trip.arrivalTime)
            );
            if (!trip) { 
                console.log("trip not defined");
            }
            if (!nextTrip) { 
                console.log("trip not defined");
            }
            if (trip.tripId != nextTrip.tripId && stopsOnTrip.length > 0) {
                extraTrips.push(new Trip(
                    trip.tripId,
                    trip.routeShortName,
                    trip.tripHeadsign,
                    stopsOnTrip
                ));
                stopsOnTrip = [];
            }
        }
        return extraTrips;
    }

    public static tripsForDatabase(trips: Trip[]): any[] {
        let arrayForDatabase = [];  
        for (let trip of trips) { 
            for (let stop of trip.stopsOnTrip) {
                arrayForDatabase.push({
                    stopId: stop.stopId,
                    departureTime: stop.departureTime,
                    arrivalTime: stop.arrivalTime,
                    routeShortName: trip.routeShortName,
                    tripHeadsign: trip.tripHeadsign, 
                    tripId: trip.id
                })
            } 
        }  
        return arrayForDatabase;
    }

    getNumberStops(): number {
        return this.stopsOnTrip.length;
    }
    
    containsStop(stopId: number): boolean {  
        for (let stopOnTrip of this.stopsOnTrip) {
            if (stopOnTrip.stopId == stopId) {
            return true;
            }
        }
        return false;
    }

    addStopOnTrip(stopOnTrip: StopOnTrip) {
        this.stopsOnTrip.push(stopOnTrip);
    }

    public static emptyTrip() {
        return new Trip(0, "", "", []);
    }

}

