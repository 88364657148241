declare var MapFeatureModel: any;
declare var SVGConverter: any;

import { Component, DoCheck, ElementRef, Input, OnChanges, SimpleChanges, forwardRef } from "@angular/core";
import { MapLayer } from "../map/map-layer";

@Component({
    selector: "geometrylayer",
    templateUrl: "./geometry-layer.component.html",
    styleUrls: ["./geometry-layer.component.scss"],
    providers: [{provide: MapLayer, useExisting: forwardRef(() => GeometryLayerComponent)}]
})
export class GeometryLayerComponent extends MapLayer implements DoCheck, OnChanges {
    mapFeatureModel = new MapFeatureModel();
    
    @Input("maxscale") maxScale;
    @Input("envelopecheck") envelopeCheck;
    @Input("deepwatch") deepWatch;
    @Input("geometries") geometries;
    @Input("geometry") geometry;
    
    toSVGPath = (new SVGConverter()).geometryToPixPath;
    circleRadius = 8;
    
    constructor(private hostRef: ElementRef) {
        super();
        
        let circleRadius = getComputedStyle(this.hostRef.nativeElement).getPropertyValue("--circle-radius");
        if (circleRadius != "") {
            this.circleRadius = parseFloat(circleRadius);
        }
    }
    
    ngDoCheck() {
        if ((this.boundsModel != null) && (this.mapFeatureModel.bounds != this.boundsModel.bounds)) {
            if (this.envelopeCheck) {
                this.mapFeatureModel.envelope = this.envelopeModel.getAnimationEnvelope();
            }
            this.mapFeatureModel.setBounds(this.boundsModel.bounds);
        }
        if ((this.focusModel != null) && (this.mapFeatureModel.centerScale != this.focusModel.animationCenterScale)) {
            if (this.envelopeCheck) {
                this.mapFeatureModel.envelope = this.envelopeModel.getAnimationEnvelope();
            }
            this.mapFeatureModel.setCenterScale(this.focusModel.animationCenterScale);
        }
        if (this.mapFeatureModel.geometries != this.geometries) {
            this.mapFeatureModel.setGeometries(this.geometries);
        } else if ((this.deepWatch) && (this.mapFeatureModel.geometries != null) && (!this.mapFeatureModel.geometries.length != this.geometries.length)) {
            this.mapFeatureModel.setGeometries(this.geometries);
        }
        if (this.mapFeatureModel.geometry != this.geometry) {
            this.mapFeatureModel.setGeometry(this.geometry);
        } else if ((this.deepWatch) && (this.mapFeatureModel.geometry != null) && (!this.mapFeatureModel.geometry.equals(this.geometry))) {
            this.mapFeatureModel.setGeometry(this.geometry);
        }
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if (changes.maxScale != null) {
            this.mapFeatureModel.maxScale(this.maxScale);
        }
        if (changes.envelopeCheck != null) {
            this.mapFeatureModel.envelopeCheck(this.envelopeCheck);
        }
    }
}
