import { DoCheck, ElementRef, ViewChild } from "@angular/core";
import { MapLayer } from "./map-layer";

export class CanvasMapLayer extends MapLayer implements DoCheck {
    @ViewChild("canvas") protected canvasRef: ElementRef;

    ngDoCheck(): void {
        if ((this.canvasRef == null) || (this.boundsModel == null)) {
            return;
        }

        var b = this.boundsModel.bounds;
        if ((this.canvasRef.nativeElement.width != b.width) || (this.canvasRef.nativeElement.height != b.height)) {
          this.canvasRef.nativeElement.width = b.width;
          this.canvasRef.nativeElement.height = b.height;
        }
    }
}
