import { AfterViewInit, DoCheck, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from "@angular/core";
import { Symbolizer } from "./symbolizer";

export class CanvasSymbolizer extends Symbolizer implements AfterViewInit, DoCheck, OnChanges {
    @ViewChild("canvas") protected canvasRef: ElementRef;
    
    @Input("inversefill") inverseFill;
    
    ngAfterViewInit() {
        this.mapFeatureModel.ctx = this.canvasRef.nativeElement.getContext("2d");
        this.mapFeatureModel.css = getComputedStyle(this.canvasRef.nativeElement);
    }
    
    ngDoCheck(): void {
        if ((this.canvasRef == null) || (this.boundsModel == null)) {
            return;
        }
        
        var bounds = this.boundsModel.bounds;
        if ((this.canvasRef.nativeElement.width != bounds.width) || (this.canvasRef.nativeElement.height != bounds.height)) {
            this.canvasRef.nativeElement.width = bounds.width;
            this.canvasRef.nativeElement.height = bounds.height;
        }
        
        super.ngDoCheck();
    }
    
    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        
        if (changes.inverseFill) {
            this.mapFeatureModel.inverseFill = this.inverseFill;
        }
    }
}
