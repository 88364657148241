declare var Bounds: any;

import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ChartModel } from "../chart-model";
import { ToolModelService } from '../../tool-model.service';
import { LanguageModelService } from '../../language-model.service';
import { WindowModelService } from '../../window-model.service';
import { ReferenceLineParams } from '../../reference-line-params';
import { ReferenceLineModelService } from '../../reference-line-model.service';

@Component({
  selector: 'app-benchmark-chart',
  templateUrl: './benchmark-chart.component.html',
  styleUrls: ['./benchmark-chart.component.scss']
})

export class BenchmarkChartComponent implements OnInit {
  chartModel = new ChartModel();
  points = [];
  netwerk: ReferenceLineParams;
  hemelsbreed: ReferenceLineParams;
  samplePoints: any[];
  curve = {};

  constructor(public toolModel: ToolModelService, public l: LanguageModelService, public windowModel: WindowModelService, public referenceLineModelService: ReferenceLineModelService) { }
  @ViewChild("svg") private svgRef: ElementRef;
    

  ngOnInit() {
        this.chartModel.yGrid.unit = "min";
        this.chartModel.yGrid.values = [0, 20, 40, 60, 80, 100];
        this.chartModel.xGrid.unit = "km";
        this.chartModel.xGrid.values = [0, 20, 40, 60, 80, 100, 120];
        this.chartModel.setOffset([10, 55, 70, 70]);
        const bounds = new Bounds(this.svgRef.nativeElement.offsetWidth, this.svgRef.nativeElement.offsetHeight);
        this.chartModel.setBounds(bounds);
  }

  ngDoCheck(): void  { 
    if (this.netwerk     != this.referenceLineModelService.netwerk ||
        this.hemelsbreed != this.referenceLineModelService.hemelsbreed) {
      this.netwerk     = this.referenceLineModelService.netwerk;
      this.hemelsbreed = this.referenceLineModelService.hemelsbreed;
      this.redrawReferenceLine();
    }

    if (this.samplePoints != this.referenceLineModelService.samplePoints) {
      this.samplePoints = this.referenceLineModelService.samplePoints;
      this.redrawSamplePoints();
    }        
     
  }  

  redrawReferenceLine() {
    if (this.netwerk == null || this.hemelsbreed == null)  return;   
    
    this.curve["pathGrensDonkerroodHemelsbreed"]  = this.getCurveGrens(1 + this.hemelsbreed.grens2 / 100, this.hemelsbreed);
    this.curve["pathGrensLichtroodHemelsbreed"]   = this.getCurveGrens(1 + this.hemelsbreed.grens1 / 100, this.hemelsbreed);
    this.curve["pathGrensLichtgroenHemelsbreed"]  = this.getCurveGrens(1 - this.hemelsbreed.grens1 / 100, this.hemelsbreed);
    this.curve["pathGrensDonkergroenHemelsbreed"] = this.getCurveGrens(1 - this.hemelsbreed.grens2 / 100, this.hemelsbreed); 
    this.curve["pathGrensDonkerroodNetwerk"]      = this.getCurveGrens(1 + this.netwerk.grens2 / 100,     this.netwerk);
    this.curve["pathGrensLichtroodNetwerk"]       = this.getCurveGrens(1 + this.netwerk.grens1 / 100,     this.netwerk);
    this.curve["pathGrensLichtgroenNetwerk"]      = this.getCurveGrens(1 - this.netwerk.grens1 / 100,     this.netwerk);
    this.curve["pathGrensDonkergroenNetwerk"]     = this.getCurveGrens(1 - this.netwerk.grens2 / 100,     this.netwerk);  
  }

  redrawSamplePoints() {
    if (this.samplePoints == null)  return;   

    this.points = []; 
    for (let samplePoint of this.samplePoints) {
      let point = {
        xHemelsbreed: samplePoint.distanceCrow,
        xNetwerk: samplePoint.distanceNetwork,
        y: samplePoint.time * 60
      }
      this.points.push(point);
    }
  }
 
 
  getCurveGrens(grens: number, referentielijn: ReferenceLineParams): string {
    let path: string = "M " + this.chartModel.transGridX(0) + "," + this.chartModel.transGridY(0);
    for (let i: number = 0; i <= 120; i++) {
        path += " L" + this.chartModel.transGridX(i) + "," + this.chartModel.transGridY(referentielijn.getReferentieReistijd(i) * grens);
    }
    for (let i: number = 120; i >= 0; i--) {
        path += " L" + this.chartModel.transGridX(i) + "," + this.chartModel.transGridY(referentielijn.getReferentieReistijd(i));
    }
    path += " Z";
    return path;
  }  
}
