import { Departure }   from './departure';

export class Stop {
    
    constructor(
        public id: number,
        public name: string,
        public x: number,
        public y: number,
        public departures?: Departure[]
    ) { }

    public static parseStop(response: any): Stop {  
        return new Stop(response.id, response.name, response.x, response.y); 
    }
 
}
