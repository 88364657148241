import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { TypePipe } from './tool-option-type.pipe';

import { AppComponent } from './app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { SingleMapComponent } from './single-map/single-map.component';
import { MapComponent } from './map/map.component';
import { TilesLayerComponent } from './tiles-layer/tiles-layer.component';
import { WMSLayerComponent } from './wms-layer/wms-layer.component';
import { PopupComponent } from './popup/popup.component';
import { MenuComponent } from './menu/menu.component';
import { BenchmarkChartComponent } from './chart/benchmark-chart/benchmark-chart.component';
import { BenchmarkTableComponent } from './benchmark-table/benchmark-table.component';
import { MapFeatureLayerComponent } from './map-feature-layer/map-feature-layer.component';
import { CanvasSymbolizerComponent } from './canvas-symbolizer/canvas-symbolizer.component';
import { GeometryLayerComponent } from './geometry-layer/geometry-layer.component';
import { NewBaseScenarioComponent } from './new-base-scenario/new-base-scenario.component';
import { ToolPanelComponent } from './tool-panel/tool-panel.component';
import { CustomComponent } from './tool-panel/custom/custom.component';
import { OdRelationsToolComponent } from './tool-panel/custom/od-relations-tool/od-relations-tool.component';
import { AppImportComponent } from './app-import/app-import.component';
import { ConsistencyChartComponent } from './chart/consistency-chart/consistency-chart.component';
import { GridComponent } from './chart/grid/grid.component';
import { GtfsComponent } from './tool-panel/custom/gtfs/gtfs.component';
import { NewMeasureScenarioComponent } from './new-measure-scenario/new-measure-scenario.component'; 
import { GenericMeasureComponent } from './tool-panel/custom/generic-measure/generic-measure.component';
import { ImportSocioComponent } from './tool-panel/custom/import-socio/import-socio.component';
import { GenericMeasurePopupComponent } from './generic-measure-popup/generic-measure-popup.component';
import { SpeedCapacityMeasureToolComponent } from './tool-panel/custom/speed-capacity-measure-tool/speed-capacity-measure-tool.component';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    SingleMapComponent,
    MapComponent,
    TilesLayerComponent,
    WMSLayerComponent,
    PopupComponent,
    MenuComponent,
    TypePipe,
    BenchmarkChartComponent,
    BenchmarkTableComponent,
    MapFeatureLayerComponent,
    CanvasSymbolizerComponent,
    GeometryLayerComponent,
    NewBaseScenarioComponent,
    ToolPanelComponent,
    CustomComponent,
    OdRelationsToolComponent,
    AppImportComponent,
    ConsistencyChartComponent,
    GridComponent,
    GtfsComponent,
    NewMeasureScenarioComponent, 
    GenericMeasureComponent, 
    GenericMeasurePopupComponent, 
    ImportSocioComponent, SpeedCapacityMeasureToolComponent 
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
