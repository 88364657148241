import { Component, Input } from "@angular/core";

@Component({
    selector: "app-grid",
    templateUrl: "./grid.component.html",
    styleUrls: ["./grid.component.scss"]
})
export class GridComponent {
    @Input("chartmodel") chartModel;
}
